const DEFAULT_COLOURS = {
    theme: '#446CFC',
    secondary: '#151F6D',
    complimentary: '#F73BA0',
    info: '#E6FDFF',
    success: '#00A88F',
    warning: '#FED141',
    danger: '#F9423A',
    headerbg: '#FFFFFF',
    headersecondary: '#0F171F'
};

export default function Window(app) {
    // Entities
    const course = window.course;
    const certificate = window.certificate;
    const client = window.client || {};
    const group = window.group;
    const link = window.link;
    const notifications = window.notifications;
    const user = window.user;
    const userId = window.userId;
    const questionTypes = window.questionTypes;
    const pricingPlans = window.pricingPlans;

    // Theme
    const theme = window.client && window.client.theme || {};
    const clientColours = theme.colours || {};
    const tileColours = theme.tile_types || {};
    const colours = { ...DEFAULT_COLOURS, ...clientColours };
    colours.primary = colours.theme;

    // Paths
    const paths = window.paths;
    const ajaxUrl = window.ajaxUrl || `${window.rootUrl || '/'}rest/builder/`;
    const rootUrl = window.rootUrl || '/';
    const redirectUrl = window.redirectUrl;
    const siteUrl = window.siteUrl;
    const websiteHostname = window.websiteHostname;
    const expectedHostname = window.expectedHostname;

    // Web Globals
    const location = window.location;
    const navigator = window.navigator;
    const localStorage = window.localStorage;
    const innerHeight = window.innerHeight;
    const opener = window.opener;

    // Flags
    const allowedFileTypes = window.allowedFileTypes;
    const canChangePassword = window.canChangePassword;
    const isLimited = window.isLimited;
    const isInvitation = window.isInvitation;
    const isThemed = window.isThemed;
    const isPreview = window.isPreview;
    const isShareLink = window.isShareLink;
    const isScorm = window.isScorm;
    const isResetSubmitted = window.isResetSubmitted;
    const isScormOnly = window.isScormOnly;
    const isUnsupportedBrowser = window.isUnsupportedBrowser;
    const isProfileIncomplete = window.isProfileIncomplete;

    // Libraries
    const recaptchaKey = window.recaptchaKey;
    const Intercom = (...args) => window.Intercom && window.Intercom(...args);

    // Other
    const error = window.error;
    const oauth2 = window.oauth2;
    const saml = window.saml;
    const apikey = window.apikey;
    const clientTitle = window.clientTitle;
    const userLimit = window.userLimit;
    const userCount = window.userCount;
    const usageLimit = window.usageLimit;
    const usageCounts = window.usageCounts;
    const courseLimit = window.courseLimit;
    const courseCount = window.courseCount;
    const recoverMessage = window.recoverMessage;

    const ua = window.navigator.userAgent.toLowerCase();
    const browser = {
        userAgent: window.navigator.userAgent,
        is: {
            ios: /ipad|iphone|ipod/.test(ua),
            safari: ua.includes('safari') && !ua.includes('chrome'),
            firefox: ua.includes('firefox'),
            touch: window && 'ontouchstart' in window,
            chrome: window && window.chrome
        }
    };
    
    const vueApp = app.vueApp || app;
    vueApp.config.globalProperties.$browser = browser;
    vueApp.config.globalProperties.$window = {
        course,
        certificate,
        client,
        colours,
        tileColours,
        isLimited,
        group,
        link,
        userLimit,
        userCount,
        usageLimit,
        usageCounts,
        courseLimit,
        courseCount,
        user,
        userId,
        location,
        navigator,
        localStorage,
        paths,
        ajaxUrl,
        rootUrl,
        apikey,
        notifications,
        redirectUrl,
        isThemed,
        isPreview,
        isScorm,
        isShareLink,
        isScormOnly,
        isUnsupportedBrowser,
        isInvitation,
        innerHeight,
        allowedFileTypes,
        siteUrl,
        expectedHostname,
        websiteHostname,
        clientTitle,
        recoverMessage,
        isResetSubmitted,
        isProfileIncomplete,
        questionTypes,
        pricingPlans,
        recaptchaKey,
        Intercom,
        opener,
        oauth2,
        saml,
        error,
        canChangePassword
    };
}
