import confetti from 'canvas-confetti';
import { analogous } from '@/helpers/colour';

const theme = window.client && window.client.theme || {};
const clientColours = theme.colours || {};
const defaultColors = analogous(clientColours.theme || '#446CFC', 5);

const $confetti = {
    defaultColors,
    colors: defaultColors,
    fire: confetti,
    randomInRange(min, max) {
        return Math.random() * (max - min) + min;
    },
    routine(configs, interval = 200, duration = 5) {
        let timer;
        const end = Date.now() + (duration * 1000);

        const p = new Promise(resolve => {
            timer = this.set.call(window, () => {
                if (Date.now() > end) resolve();
                let skew = 1;
                configs.forEach(config => {
                    if (config.skew) skew = Math.max(0.8, skew - 0.001);
                    if (config.origin === 'random') config = { ...config, origin: { x: Math.random(), y: (Math.random() * skew) - 0.2 }};
                    if (config.origin === 'random-x') config = { ...config, origin: { x: Math.random(), y: config.y } };
                    if (config.particleCount === 1) {
                        // Manually get random item from config.colors when the particle count is 1
                        const colors = [config.colors[Math.floor(Math.random() * config.colors.length)]];
                        config = { ...config, colors };
                    }
                    confetti(config);
                });
            }, interval);
        }).then(() => this.clear.call(window, timer));

        p.cancel = () => {
            this.clear.call(window, timer);
            confetti.reset();
        };

        return p;
    },
    fireworks() {
        const configs = [{
            startVelocity: 30,
            spread: 360,
            ticks: 60,
            shapes: ['square'],
            colors: this.colors,
            origin: 'random',
            zIndex: 1000
        }];

        return this.routine(configs, 200, 5);
    },
    cannons() {
        const configs = [{
            angle: 60,
            spread: 55,
            colors: this.colors,
            origin: { x: 0 },
            zIndex: 1000
        }, {
            angle: 120,
            spread: 55,
            colors: this.colors,
            origin: { x: 1 },
            zIndex: 1000
        }];
        return this.routine(configs, 500, 5);
    },
    snow(duration = 5) {
        const configs = [{
            particleCount: 1,
            startVelocity: 0,
            ticks: 200,
            origin: 'random-x',
            y: 0,
            colors: this.colors,
            gravity: this.randomInRange(2, 3),
            scalar: this.randomInRange(0.4, 1),
            drift: this.randomInRange(-0.4, 0.4),
            zIndex: 1000
        }];
        return this.routine(configs, 10, duration);
    },
    set: setInterval,
    clear: clearInterval,
    install(app, { set, clear } = { set: setInterval, clear: clearInterval }) {
        this.set = set;
        this.clear = clear;
    }
};

export default function Confetti(app) {
    $confetti.install(app);

    const vueApp = app.vueApp || app;
    vueApp.config.globalProperties.$confetti = $confetti;
}
