export const timeout = function(callback, ms) {
    let id;
    const timer = new Promise(resolve => id = setTimeout(resolve, ms)).then(callback);
    timer.cancel = () => clearTimeout(id);
    return timer;
};

export const cancel = function(timer) {
    if (timer && timer.cancel) timer.cancel();
};

export const debounce = function(callback, ms, timer = null) {
    cancel(timer);
    const _timer = timeout(() => callback(_timer), ms);
    return _timer;
};
export default function Timeout(app) {
    const vueApp = app.vueApp || app;
    vueApp.config.globalProperties.$timeout = timeout;
    vueApp.config.globalProperties.$cancel = cancel;
    vueApp.config.globalProperties.$debounce = debounce;
}
