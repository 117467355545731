export const countries = [
    { emoji: '🇦🇫', name: 'Afghanistan', code: '+93' },
    { emoji: '🇦🇱', name: 'Albania', code: '+355' },
    { emoji: '🇩🇿', name: 'Algeria', code: '+213' },
    { emoji: '🇦🇸', name: 'American Samoa', code: '+1-684' },
    { emoji: '🇦🇩', name: 'Andorra', code: '+376' },
    { emoji: '🇦🇴', name: 'Angola', code: '+244' },
    { emoji: '🇦🇮', name: 'Anguilla', code: '+1-264' },
    { emoji: '🇦🇶', name: 'Antarctica', code: '+672' },
    { emoji: '🇦🇬', name: 'Antigua and Barbuda', code: '+1-268' },
    { emoji: '🇦🇷', name: 'Argentina', code: '+54' },
    { emoji: '🇦🇲', name: 'Armenia', code: '+374' },
    { emoji: '🇦🇼', name: 'Aruba', code: '+297' },
    { emoji: '🇦🇺', name: 'Australia', code: '+61' },
    { emoji: '🇦🇹', name: 'Austria', code: '+43' },
    { emoji: '🇦🇿', name: 'Azerbaijan', code: '+994' },
    { emoji: '🇧🇸', name: 'Bahamas', code: '+1-242' },
    { emoji: '🇧🇭', name: 'Bahrain', code: '+973' },
    { emoji: '🇧🇩', name: 'Bangladesh', code: '+880' },
    { emoji: '🇧🇧', name: 'Barbados', code: '+1-246' },
    { emoji: '🇧🇾', name: 'Belarus', code: '+375' },
    { emoji: '🇧🇪', name: 'Belgium', code: '+32' },
    { emoji: '🇧🇿', name: 'Belize', code: '+501' },
    { emoji: '🇧🇯', name: 'Benin', code: '+229' },
    { emoji: '🇧🇲', name: 'Bermuda', code: '+1-441' },
    { emoji: '🇧🇹', name: 'Bhutan', code: '+975' },
    { emoji: '🇧🇴', name: 'Bolivia', code: '+591' },
    { emoji: '🇧🇦', name: 'Bosnia and Herzegovina', code: '+387' },
    { emoji: '🇧🇼', name: 'Botswana', code: '+267' },
    { emoji: '🇧🇷', name: 'Brazil', code: '+55' },
    { emoji: '🇮🇴', name: 'British Indian Ocean Territory', code: '+246' },
    { emoji: '🇧🇳', name: 'Brunei', code: '+673' },
    { emoji: '🇧🇬', name: 'Bulgaria', code: '+359' },
    { emoji: '🇧🇫', name: 'Burkina Faso', code: '+226' },
    { emoji: '🇧🇮', name: 'Burundi', code: '+257' },
    { emoji: '🇰🇭', name: 'Cambodia', code: '+855' },
    { emoji: '🇨🇲', name: 'Cameroon', code: '+237' },
    { emoji: '🇨🇦', name: 'Canada', code: '+1' },
    { emoji: '🇨🇻', name: 'Cape Verde', code: '+238' },
    { emoji: '🇰🇾', name: 'Cayman Islands', code: '+1-345' },
    { emoji: '🇨🇫', name: 'Central African Republic', code: '+236' },
    { emoji: '🇹🇩', name: 'Chad', code: '+235' },
    { emoji: '🇨🇱', name: 'Chile', code: '+56' },
    { emoji: '🇨🇳', name: 'China', code: '+86' },
    { emoji: '🇨🇽', name: 'Christmas Island', code: '+61' },
    { emoji: '🇨🇴', name: 'Colombia', code: '+57' },
    { emoji: '🇰🇲', name: 'Comoros', code: '+269' },
    { emoji: '🇨🇬', name: 'Republic of the Congo', code: '+242' },
    { emoji: '🇨🇰', name: 'Cook Islands', code: '+682' },
    { emoji: '🇨🇷', name: 'Costa Rica', code: '+506' },
    { emoji: '🇭🇷', name: 'Croatia', code: '+385' },
    { emoji: '🇨🇺', name: 'Cuba', code: '+53' },
    { emoji: '🇨🇾', name: 'Cyprus', code: '+357' },
    { emoji: '🇩🇰', name: 'Denmark', code: '+45' },
    { emoji: '🇩🇯', name: 'Djibouti', code: '+253' },
    { emoji: '🇩🇲', name: 'Dominica', code: '+1-767' },
    { emoji: '🇩🇴', name: 'Dominican Republic', code: '+1-809, 1-829, 1-849' },
    { emoji: '🇪🇨', name: 'Ecuador', code: '+593' },
    { emoji: '🇪🇬', name: 'Egypt', code: '+20' },
    { emoji: '🇸🇻', name: 'El Salvador', code: '+503' },
    { emoji: '🇬🇶', name: 'Equatorial Guinea', code: '+240' },
    { emoji: '🇪🇷', name: 'Eritrea', code: '+291' },
    { emoji: '🇪🇪', name: 'Estonia', code: '+372' },
    { emoji: '🇪🇹', name: 'Ethiopia', code: '+251' },
    { emoji: '🇫🇰', name: 'Falkland Islands', code: '+500' },
    { emoji: '🇫🇴', name: 'Faroe Islands', code: '+298' },
    { emoji: '🇫🇯', name: 'Fiji', code: '+679' },
    { emoji: '🇫🇮', name: 'Finland', code: '+358' },
    { emoji: '🇫🇷', name: 'France', code: '+33' },
    { emoji: '🇵🇫', name: 'French Polynesia', code: '+689' },
    { emoji: '🇬🇦', name: 'Gabon', code: '+241' },
    { emoji: '🇬🇲', name: 'Gambia', code: '+220' },
    { emoji: '🇬🇪', name: 'Georgia', code: '+995' },
    { emoji: '🇩🇪', name: 'Germany', code: '+49' },
    { emoji: '🇬🇭', name: 'Ghana', code: '+233' },
    { emoji: '🇬🇮', name: 'Gibraltar', code: '+350' },
    { emoji: '🇬🇷', name: 'Greece', code: '+30' },
    { emoji: '🇬🇱', name: 'Greenland', code: '+299' },
    { emoji: '🇬🇩', name: 'Grenada', code: '+1-473' },
    { emoji: '🇬🇺', name: 'Guam', code: '+1-671' },
    { emoji: '🇬🇹', name: 'Guatemala', code: '+502' },
    { emoji: '🇬🇬', name: 'Guernsey', code: '+44-1481' },
    { emoji: '🇬🇳', name: 'Guinea', code: '+224' },
    { emoji: '🇬🇼', name: 'Guinea-Bissau', code: '+245' },
    { emoji: '🇬🇾', name: 'Guyana', code: '+592' },
    { emoji: '🇭🇹', name: 'Haiti', code: '+509' },
    { emoji: '🇭🇳', name: 'Honduras', code: '+504' },
    { emoji: '🇭🇰', name: 'Hong Kong', code: '+852' },
    { emoji: '🇭🇺', name: 'Hungary', code: '+36' },
    { emoji: '🇮🇸', name: 'Iceland', code: '+354' },
    { emoji: '🇮🇳', name: 'India', code: '+91' },
    { emoji: '🇮🇩', name: 'Indonesia', code: '+62' },
    { emoji: '🇮🇷', name: 'Iran', code: '+98' },
    { emoji: '🇮🇶', name: 'Iraq', code: '+964' },
    { emoji: '🇮🇪', name: 'Ireland', code: '+353' },
    { emoji: '🇮🇲', name: 'Isle of Man', code: '+44-1624' },
    { emoji: '🇮🇱', name: 'Israel', code: '+972' },
    { emoji: '🇮🇹', name: 'Italy', code: '+39' },
    { emoji: '🇯🇲', name: 'Jamaica', code: '+1-876' },
    { emoji: '🇯🇵', name: 'Japan', code: '+81' },
    { emoji: '🇯🇪', name: 'Jersey', code: '+44-1534' },
    { emoji: '🇯🇴', name: 'Jordan', code: '+962' },
    { emoji: '🇰🇿', name: 'Kazakhstan', code: '+7' },
    { emoji: '🇰🇪', name: 'Kenya', code: '+254' },
    { emoji: '🇰🇮', name: 'Kiribati', code: '+686' },
    { emoji: '🇰🇵', name: 'North Korea', code: '+850' },
    { emoji: '🇰🇷', name: 'South Korea', code: '+82' },
    { emoji: '🇽🇰', name: 'Kosovo', code: '+383' },
    { emoji: '🇰🇼', name: 'Kuwait', code: '+965' },
    { emoji: '🇰🇬', name: 'Kyrgyzstan', code: '+996' },
    { emoji: '🇱🇦', name: 'Laos', code: '+856' },
    { emoji: '🇱🇻', name: 'Latvia', code: '+371' },
    { emoji: '🇱🇧', name: 'Lebanon', code: '+961' },
    { emoji: '🇱🇸', name: 'Lesotho', code: '+266' },
    { emoji: '🇱🇷', name: 'Liberia', code: '+231' },
    { emoji: '🇱🇾', name: 'Libya', code: '+218' },
    { emoji: '🇱🇮', name: 'Liechtenstein', code: '+423' },
    { emoji: '🇱🇹', name: 'Lithuania', code: '+370' },
    { emoji: '🇱🇺', name: 'Luxembourg', code: '+352' },
    { emoji: '🇲🇴', name: 'Macau', code: '+853' },
    { emoji: '🇲🇬', name: 'Madagascar', code: '+261' },
    { emoji: '🇲🇼', name: 'Malawi', code: '+265' },
    { emoji: '🇲🇾', name: 'Malaysia', code: '+60' },
    { emoji: '🇲🇻', name: 'Maldives', code: '+960' },
    { emoji: '🇲🇱', name: 'Mali', code: '+223' },
    { emoji: '🇲🇹', name: 'Malta', code: '+356' },
    { emoji: '🇲🇭', name: 'Marshall Islands', code: '+692' },
    { emoji: '🇲🇷', name: 'Mauritania', code: '+222' },
    { emoji: '🇲🇺', name: 'Mauritius', code: '+230' },
    { emoji: '🇾🇹', name: 'Mayotte', code: '+262' },
    { emoji: '🇲🇽', name: 'Mexico', code: '+52' },
    { emoji: '🇫🇲', name: 'Micronesia', code: '+691' },
    { emoji: '🇲🇩', name: 'Moldova', code: '+373' },
    { emoji: '🇲🇨', name: 'Monaco', code: '+377' },
    { emoji: '🇲🇳', name: 'Mongolia', code: '+976' },
    { emoji: '🇲🇪', name: 'Montenegro', code: '+382' },
    { emoji: '🇲🇸', name: 'Montserrat', code: '+1-664' },
    { emoji: '🇲🇦', name: 'Morocco', code: '+212' },
    { emoji: '🇲🇿', name: 'Mozambique', code: '+258' },
    { emoji: '🇲🇲', name: 'Myanmar', code: '+95' },
    { emoji: '🇳🇦', name: 'Namibia', code: '+264' },
    { emoji: '🇳🇷', name: 'Nauru', code: '+674' },
    { emoji: '🇳🇵', name: 'Nepal', code: '+977' },
    { emoji: '🇳🇱', name: 'Netherlands', code: '+31' },
    { emoji: '🇳🇨', name: 'New Caledonia', code: '+687' },
    { emoji: '🇳🇿', name: 'New Zealand', code: '+64' },
    { emoji: '🇳🇮', name: 'Nicaragua', code: '+505' },
    { emoji: '🇳🇪', name: 'Niger', code: '+227' },
    { emoji: '🇳🇬', name: 'Nigeria', code: '+234' },
    { emoji: '🇳🇺', name: 'Niue', code: '+683' },
    { emoji: '🇲🇵', name: 'Northern Mariana Islands', code: '+1-670' },
    { emoji: '🇳🇴', name: 'Norway', code: '+47' },
    { emoji: '🇴🇲', name: 'Oman', code: '+968' },
    { emoji: '🇵🇰', name: 'Pakistan', code: '+92' },
    { emoji: '🇵🇼', name: 'Palau', code: '+680' },
    { emoji: '🇵🇸', name: 'Palestine', code: '+970' },
    { emoji: '🇵🇦', name: 'Panama', code: '+507' },
    { emoji: '🇵🇬', name: 'Papua New Guinea', code: '+675' },
    { emoji: '🇵🇾', name: 'Paraguay', code: '+595' },
    { emoji: '🇵🇪', name: 'Peru', code: '+51' },
    { emoji: '🇵🇭', name: 'Philippines', code: '+63' },
    { emoji: '🇵🇱', name: 'Poland', code: '+48' },
    { emoji: '🇵🇹', name: 'Portugal', code: '+351' },
    { emoji: '🇵🇷', name: 'Puerto Rico', code: '+1-787, 1-939' },
    { emoji: '🇶🇦', name: 'Qatar', code: '+974' },
    { emoji: '🇷🇴', name: 'Romania', code: '+40' },
    { emoji: '🇷🇺', name: 'Russia', code: '+7' },
    { emoji: '🇷🇼', name: 'Rwanda', code: '+250' },
    { emoji: '🇰🇳', name: 'Saint Kitts and Nevis', code: '+1-869' },
    { emoji: '🇱🇨', name: 'Saint Lucia', code: '+1-758' },
    { emoji: '🇲🇫', name: 'Saint Martin', code: '+590' },
    { emoji: '🇵🇲', name: 'Saint Pierre and Miquelon', code: '+508' },
    { emoji: '🇻🇨', name: 'Saint Vincent and the Grenadines', code: '+1-784' },
    { emoji: '🇼🇸', name: 'Samoa', code: '+685' },
    { emoji: '🇸🇲', name: 'San Marino', code: '+378' },
    { emoji: '🇸🇦', name: 'Saudi Arabia', code: '+966' },
    { emoji: '🇸🇳', name: 'Senegal', code: '+221' },
    { emoji: '🇷🇸', name: 'Serbia', code: '+381' },
    { emoji: '🇸🇨', name: 'Seychelles', code: '+248' },
    { emoji: '🇸🇱', name: 'Sierra Leone', code: '+232' },
    { emoji: '🇸🇬', name: 'Singapore', code: '+65' },
    { emoji: '🇸🇽', name: 'Sint Maarten', code: '+1-721' },
    { emoji: '🇸🇰', name: 'Slovakia', code: '+421' },
    { emoji: '🇸🇮', name: 'Slovenia', code: '+386' },
    { emoji: '🇸🇧', name: 'Solomon Islands', code: '+677' },
    { emoji: '🇸🇴', name: 'Somalia', code: '+252' },
    { emoji: '🇿🇦', name: 'South Africa', code: '+27' },
    { emoji: '🇸🇸', name: 'South Sudan', code: '+211' },
    { emoji: '🇪🇸', name: 'Spain', code: '+34' },
    { emoji: '🇱🇰', name: 'Sri Lanka', code: '+94' },
    { emoji: '🇸🇩', name: 'Sudan', code: '+249' },
    { emoji: '🇸🇷', name: 'Suriname', code: '+597' },
    { emoji: '🇸🇯', name: 'Svalbard and Jan Mayen', code: '+47' },
    { emoji: '🇸🇪', name: 'Sweden', code: '+46' },
    { emoji: '🇨🇭', name: 'Switzerland', code: '+41' },
    { emoji: '🇸🇾', name: 'Syria', code: '+963' },
    { emoji: '🇹🇼', name: 'Taiwan', code: '+886' },
    { emoji: '🇹🇯', name: 'Tajikistan', code: '+992' },
    { emoji: '🇹🇿', name: 'Tanzania', code: '+255' },
    { emoji: '🇹🇭', name: 'Thailand', code: '+66' },
    { emoji: '🇹🇬', name: 'Togo', code: '+228' },
    { emoji: '🇹🇰', name: 'Tokelau', code: '+690' },
    { emoji: '🇹🇴', name: 'Tonga', code: '+676' },
    { emoji: '🇹🇹', name: 'Trinidad and Tobago', code: '+1-868' },
    { emoji: '🇹🇳', name: 'Tunisia', code: '+216' },
    { emoji: '🇹🇷', name: 'Turkey', code: '+90' },
    { emoji: '🇹🇲', name: 'Turkmenistan', code: '+993' },
    { emoji: '🇹🇨', name: 'Turks and Caicos Islands', code: '+1-649' },
    { emoji: '🇹🇻', name: 'Tuvalu', code: '+688' },
    { emoji: '🇺🇬', name: 'Uganda', code: '+256' },
    { emoji: '🇺🇦', name: 'Ukraine', code: '+380' },
    { emoji: '🇦🇪', name: 'United Arab Emirates', code: '+971' },
    { emoji: '🇬🇧', name: 'United Kingdom', code: '+44' },
    { emoji: '🇺🇸', name: 'United States', code: '+1' },
    { emoji: '🇺🇾', name: 'Uruguay', code: '+598' },
    { emoji: '🇺🇿', name: 'Uzbekistan', code: '+998' },
    { emoji: '🇻🇺', name: 'Vanuatu', code: '+678' },
    { emoji: '🇻🇪', name: 'Venezuela', code: '+58' },
    { emoji: '🇻🇳', name: 'Vietnam', code: '+84' },
    { emoji: '🇻🇬', name: 'British Virgin Islands', code: '+1-284' },
    { emoji: '🇼🇫', name: 'Wallis and Futuna', code: '+681' },
    { emoji: '🇪🇭', name: 'Western Sahara', code: '+212' },
    { emoji: '🇾🇪', name: 'Yemen', code: '+967' },
    { emoji: '🇿🇲', name: 'Zambia', code: '+260' },
    { emoji: '🇿🇼', name: 'Zimbabwe', code: '+263' }
];

export const timezones = [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Addis_Ababa',
    'Africa/Algiers',
    'Africa/Asmara',
    'Africa/Asmera',
    'Africa/Bamako',
    'Africa/Bangui',
    'Africa/Banjul',
    'Africa/Bissau',
    'Africa/Blantyre',
    'Africa/Brazzaville',
    'Africa/Bujumbura',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Ceuta',
    'Africa/Conakry',
    'Africa/Dakar',
    'Africa/Dar_es_Salaam',
    'Africa/Djibouti',
    'Africa/Douala',
    'Africa/El_Aaiun',
    'Africa/Freetown',
    'Africa/Gaborone',
    'Africa/Harare',
    'Africa/Johannesburg',
    'Africa/Juba',
    'Africa/Kampala',
    'Africa/Khartoum',
    'Africa/Kigali',
    'Africa/Kinshasa',
    'Africa/Lagos',
    'Africa/Libreville',
    'Africa/Lome',
    'Africa/Luanda',
    'Africa/Lubumbashi',
    'Africa/Lusaka',
    'Africa/Malabo',
    'Africa/Maputo',
    'Africa/Maseru',
    'Africa/Mbabane',
    'Africa/Mogadishu',
    'Africa/Monrovia',
    'Africa/Nairobi',
    'Africa/Ndjamena',
    'Africa/Niamey',
    'Africa/Nouakchott',
    'Africa/Ouagadougou',
    'Africa/Porto-Novo',
    'Africa/Sao_Tome',
    'Africa/Timbuktu',
    'Africa/Tripoli',
    'Africa/Tunis',
    'Africa/Windhoek',
    'America/Adak',
    'America/Anchorage',
    'America/Anguilla',
    'America/Antigua',
    'America/Araguaina',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca',
    'America/Argentina/ComodRivadavia',
    'America/Argentina/Cordoba',
    'America/Argentina/Jujuy',
    'America/Argentina/La_Rioja',
    'America/Argentina/Mendoza',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Salta',
    'America/Argentina/San_Juan',
    'America/Argentina/San_Luis',
    'America/Argentina/Tucuman',
    'America/Argentina/Ushuaia',
    'America/Aruba',
    'America/Asuncion',
    'America/Atikokan',
    'America/Atka',
    'America/Bahia',
    'America/Bahia_Banderas',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Blanc-Sablon',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Buenos_Aires',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Catamarca',
    'America/Cayenne',
    'America/Cayman',
    'America/Chicago',
    'America/Chihuahua',
    'America/Coral_Harbour',
    'America/Cordoba',
    'America/Costa_Rica',
    'America/Creston',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver',
    'America/Detroit',
    'America/Dominica',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Ensenada',
    'America/Fort_Nelson',
    'America/Fort_Wayne',
    'America/Fortaleza',
    'America/Glace_Bay',
    'America/Godthab',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Grenada',
    'America/Guadeloupe',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indianapolis',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Jujuy',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Knox_IN',
    'America/Kralendijk',
    'America/La_Paz',
    'America/Lima',
    'America/Los_Angeles',
    'America/Louisville',
    'America/Lower_Princes',
    'America/Maceio',
    'America/Managua',
    'America/Manaus',
    'America/Marigot',
    'America/Martinique',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Mendoza',
    'America/Menominee',
    'America/Merida',
    'America/Metlakatla',
    'America/Mexico_City',
    'America/Miquelon',
    'America/Moncton',
    'America/Monterrey',
    'America/Montevideo',
    'America/Montreal',
    'America/Montserrat',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Nuuk',
    'America/Ojinaga',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Acre',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Punta_Arenas',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Rosario',
    'America/Santa_Isabel',
    'America/Santarem',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Shiprock',
    'America/Sitka',
    'America/St_Barthelemy',
    'America/St_Johns',
    'America/St_Kitts',
    'America/St_Lucia',
    'America/St_Thomas',
    'America/St_Vincent',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Tortola',
    'America/Vancouver',
    'America/Virgin',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Macquarie',
    'Antarctica/Mawson',
    'Antarctica/McMurdo',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/South_Pole',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'Arctic/Longyearbyen',
    'Asia/Aden',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Anadyr',
    'Asia/Aqtau',
    'Asia/Aqtobe',
    'Asia/Ashgabat',
    'Asia/Ashkhabad',
    'Asia/Atyrau',
    'Asia/Baghdad',
    'Asia/Bahrain',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Barnaul',
    'Asia/Beirut',
    'Asia/Bishkek',
    'Asia/Brunei',
    'Asia/Calcutta',
    'Asia/Chita',
    'Asia/Choibalsan',
    'Asia/Chongqing',
    'Asia/Chungking',
    'Asia/Colombo',
    'Asia/Dacca',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dili',
    'Asia/Dubai',
    'Asia/Dushanbe',
    'Asia/Famagusta',
    'Asia/Gaza',
    'Asia/Harbin',
    'Asia/Hebron',
    'Asia/Ho_Chi_Minh',
    'Asia/Hong_Kong',
    'Asia/Hovd',
    'Asia/Irkutsk',
    'Asia/Istanbul',
    'Asia/Jakarta',
    'Asia/Jayapura',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Karachi',
    'Asia/Kashgar',
    'Asia/Kathmandu',
    'Asia/Katmandu',
    'Asia/Khandyga',
    'Asia/Kolkata',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Asia/Kuwait',
    'Asia/Macao',
    'Asia/Macau',
    'Asia/Magadan',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Muscat',
    'Asia/Nicosia',
    'Asia/Novokuznetsk',
    'Asia/Novosibirsk',
    'Asia/Omsk',
    'Asia/Oral',
    'Asia/Phnom_Penh',
    'Asia/Pontianak',
    'Asia/Pyongyang',
    'Asia/Qatar',
    'Asia/Qostanay',
    'Asia/Qyzylorda',
    'Asia/Rangoon',
    'Asia/Riyadh',
    'Asia/Saigon',
    'Asia/Sakhalin',
    'Asia/Samarkand',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Srednekolymsk',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tbilisi',
    'Asia/Tehran',
    'Asia/Tel_Aviv',
    'Asia/Thimbu',
    'Asia/Thimphu',
    'Asia/Tokyo',
    'Asia/Tomsk',
    'Asia/Ujung_Pandang',
    'Asia/Ulaanbaatar',
    'Asia/Ulan_Bator',
    'Asia/Urumqi',
    'Asia/Ust-Nera',
    'Asia/Vientiane',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yangon',
    'Asia/Yekaterinburg',
    'Asia/Yerevan',
    'Atlantic/Azores',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faeroe',
    'Atlantic/Faroe',
    'Atlantic/Jan_Mayen',
    'Atlantic/Madeira',
    'Atlantic/Reykjavik',
    'Atlantic/South_Georgia',
    'Atlantic/St_Helena',
    'Atlantic/Stanley',
    'Australia/ACT',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Canberra',
    'Australia/Currie',
    'Australia/Darwin',
    'Australia/Eucla',
    'Australia/Hobart',
    'Australia/LHI',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/NSW',
    'Australia/North',
    'Australia/Perth',
    'Australia/Queensland',
    'Australia/South',
    'Australia/Sydney',
    'Australia/Tasmania',
    'Australia/Victoria',
    'Australia/West',
    'Australia/Yancowinna',
    'Brazil/Acre',
    'Brazil/DeNoronha',
    'Brazil/East',
    'Brazil/West',
    'CET',
    'CST6CDT',
    'Canada/Atlantic',
    'Canada/Central',
    'Canada/Eastern',
    'Canada/Mountain',
    'Canada/Newfoundland',
    'Canada/Pacific',
    'Canada/Saskatchewan',
    'Canada/Yukon',
    'Chile/Continental',
    'Chile/EasterIsland',
    'Cuba',
    'EET',
    'EST',
    'EST5EDT',
    'Egypt',
    'Eire',
    'Etc/GMT',
    'Etc/GMT+0',
    'Etc/GMT+1',
    'Etc/GMT+10',
    'Etc/GMT+11',
    'Etc/GMT+12',
    'Etc/GMT+2',
    'Etc/GMT+3',
    'Etc/GMT+4',
    'Etc/GMT+5',
    'Etc/GMT+6',
    'Etc/GMT+7',
    'Etc/GMT+8',
    'Etc/GMT+9',
    'Etc/GMT-0',
    'Etc/GMT-1',
    'Etc/GMT-10',
    'Etc/GMT-11',
    'Etc/GMT-12',
    'Etc/GMT-13',
    'Etc/GMT-14',
    'Etc/GMT-2',
    'Etc/GMT-3',
    'Etc/GMT-4',
    'Etc/GMT-5',
    'Etc/GMT-6',
    'Etc/GMT-7',
    'Etc/GMT-8',
    'Etc/GMT-9',
    'Etc/GMT0',
    'Etc/Greenwich',
    'Etc/UCT',
    'Etc/UTC',
    'Etc/Universal',
    'Etc/Zulu',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Astrakhan',
    'Europe/Athens',
    'Europe/Belfast',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Bratislava',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Busingen',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Guernsey',
    'Europe/Helsinki',
    'Europe/Isle_of_Man',
    'Europe/Istanbul',
    'Europe/Jersey',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Kirov',
    'Europe/Lisbon',
    'Europe/Ljubljana',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Mariehamn',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Nicosia',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Podgorica',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/San_Marino',
    'Europe/Sarajevo',
    'Europe/Saratov',
    'Europe/Simferopol',
    'Europe/Skopje',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Tiraspol',
    'Europe/Ulyanovsk',
    'Europe/Uzhgorod',
    'Europe/Vaduz',
    'Europe/Vatican',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zagreb',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'GB',
    'GB-Eire',
    'GMT',
    'GMT+0',
    'GMT-0',
    'GMT0',
    'Greenwich',
    'HST',
    'Hongkong',
    'Iceland',
    'Indian/Antananarivo',
    'Indian/Chagos',
    'Indian/Christmas',
    'Indian/Cocos',
    'Indian/Comoro',
    'Indian/Kerguelen',
    'Indian/Mahe',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Mayotte',
    'Indian/Reunion',
    'Iran',
    'Israel',
    'Jamaica',
    'Japan',
    'Kwajalein',
    'Libya',
    'MET',
    'MST',
    'MST7MDT',
    'Mexico/BajaNorte',
    'Mexico/BajaSur',
    'Mexico/General',
    'NZ',
    'NZ-CHAT',
    'Navajo',
    'PRC',
    'PST8PDT',
    'Pacific/Apia',
    'Pacific/Auckland',
    'Pacific/Bougainville',
    'Pacific/Chatham',
    'Pacific/Chuuk',
    'Pacific/Easter',
    'Pacific/Efate',
    'Pacific/Enderbury',
    'Pacific/Fakaofo',
    'Pacific/Fiji',
    'Pacific/Funafuti',
    'Pacific/Galapagos',
    'Pacific/Gambier',
    'Pacific/Guadalcanal',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Johnston',
    'Pacific/Kiritimati',
    'Pacific/Kosrae',
    'Pacific/Kwajalein',
    'Pacific/Majuro',
    'Pacific/Marquesas',
    'Pacific/Midway',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Palau',
    'Pacific/Pitcairn',
    'Pacific/Pohnpei',
    'Pacific/Ponape',
    'Pacific/Port_Moresby',
    'Pacific/Rarotonga',
    'Pacific/Saipan',
    'Pacific/Samoa',
    'Pacific/Tahiti',
    'Pacific/Tarawa',
    'Pacific/Tongatapu',
    'Pacific/Truk',
    'Pacific/Wake',
    'Pacific/Wallis',
    'Pacific/Yap',
    'Poland',
    'Portugal',
    'ROC',
    'ROK',
    'Singapore',
    'Turkey',
    'UCT',
    'US/Alaska',
    'US/Aleutian',
    'US/Arizona',
    'US/Central',
    'US/East-Indiana',
    'US/Eastern',
    'US/Hawaii',
    'US/Indiana-Starke',
    'US/Michigan',
    'US/Mountain',
    'US/Pacific',
    'US/Samoa',
    'UTC',
    'Universal',
    'W-SU',
    'WET',
    'Zulu'
];

export const locales = {
    'af': () => import('dayjs/locale/af'),
    'am': () => import('dayjs/locale/am'),
    'ar-dz': () => import('dayjs/locale/ar-dz'),
    'ar-iq': () => import('dayjs/locale/ar-iq'),
    'ar-kw': () => import('dayjs/locale/ar-kw'),
    'ar-ly': () => import('dayjs/locale/ar-ly'),
    'ar-ma': () => import('dayjs/locale/ar-ma'),
    'ar-sa': () => import('dayjs/locale/ar-sa'),
    'ar-tn': () => import('dayjs/locale/ar-tn'),
    'ar': () => import('dayjs/locale/ar'),
    'az': () => import('dayjs/locale/az'),
    'be': () => import('dayjs/locale/be'),
    'bg': () => import('dayjs/locale/bg'),
    'bi': () => import('dayjs/locale/bi'),
    'bm': () => import('dayjs/locale/bm'),
    'bn': () => import('dayjs/locale/bn'),
    'bo': () => import('dayjs/locale/bo'),
    'br': () => import('dayjs/locale/br'),
    'bs': () => import('dayjs/locale/bs'),
    'ca': () => import('dayjs/locale/ca'),
    'cs': () => import('dayjs/locale/cs'),
    'cv': () => import('dayjs/locale/cv'),
    'cy': () => import('dayjs/locale/cy'),
    'da': () => import('dayjs/locale/da'),
    'de-at': () => import('dayjs/locale/de-at'),
    'de-ch': () => import('dayjs/locale/de-ch'),
    'de': () => import('dayjs/locale/de'),
    'dv': () => import('dayjs/locale/dv'),
    'el': () => import('dayjs/locale/el'),
    'en-au': () => import('dayjs/locale/en-au'),
    'en-ca': () => import('dayjs/locale/en-ca'),
    'en-gb': () => import('dayjs/locale/en-gb'),
    'en-ie': () => import('dayjs/locale/en-ie'),
    'en-il': () => import('dayjs/locale/en-il'),
    'en-in': () => import('dayjs/locale/en-in'),
    'en-nz': () => import('dayjs/locale/en-nz'),
    'en-sg': () => import('dayjs/locale/en-sg'),
    'en-tt': () => import('dayjs/locale/en-tt'),
    'en': () => import('dayjs/locale/en'),
    'eo': () => import('dayjs/locale/eo'),
    'es-do': () => import('dayjs/locale/es-do'),
    'es-mx': () => import('dayjs/locale/es-mx'),
    'et': () => import('dayjs/locale/et'),
    'eu': () => import('dayjs/locale/eu'),
    'fa': () => import('dayjs/locale/fa'),
    'fi': () => import('dayjs/locale/fi'),
    'fo': () => import('dayjs/locale/fo'),
    'fr-ca': () => import('dayjs/locale/fr-ca'),
    'fr-ch': () => import('dayjs/locale/fr-ch'),
    'fr': () => import('dayjs/locale/fr'),
    'fy': () => import('dayjs/locale/fy'),
    'ga': () => import('dayjs/locale/ga'),
    'gd': () => import('dayjs/locale/gd'),
    'gl': () => import('dayjs/locale/gl'),
    'gom-latn': () => import('dayjs/locale/gom-latn'),
    'gu': () => import('dayjs/locale/gu'),
    'he': () => import('dayjs/locale/he'),
    'hi': () => import('dayjs/locale/hi'),
    'hr': () => import('dayjs/locale/hr'),
    'ht': () => import('dayjs/locale/ht'),
    'hu': () => import('dayjs/locale/hu'),
    'hy-am': () => import('dayjs/locale/hy-am'),
    'id': () => import('dayjs/locale/id'),
    'is': () => import('dayjs/locale/is'),
    'it-ch': () => import('dayjs/locale/it-ch'),
    'it': () => import('dayjs/locale/it'),
    'ja': () => import('dayjs/locale/ja'),
    'jv': () => import('dayjs/locale/jv'),
    'ka': () => import('dayjs/locale/ka'),
    'kk': () => import('dayjs/locale/kk'),
    'km': () => import('dayjs/locale/km'),
    'kn': () => import('dayjs/locale/kn'),
    'ko': () => import('dayjs/locale/ko'),
    'ku': () => import('dayjs/locale/ku'),
    'ky': () => import('dayjs/locale/ky'),
    'lb': () => import('dayjs/locale/lb'),
    'lo': () => import('dayjs/locale/lo'),
    'lt': () => import('dayjs/locale/lt'),
    'lv': () => import('dayjs/locale/lv'),
    'me': () => import('dayjs/locale/me'),
    'mi': () => import('dayjs/locale/mi'),
    'mk': () => import('dayjs/locale/mk'),
    'ml': () => import('dayjs/locale/ml'),
    'mn': () => import('dayjs/locale/mn'),
    'mr': () => import('dayjs/locale/mr'),
    'ms-my': () => import('dayjs/locale/ms-my'),
    'ms': () => import('dayjs/locale/ms'),
    'mt': () => import('dayjs/locale/mt'),
    'my': () => import('dayjs/locale/my'),
    'nb': () => import('dayjs/locale/nb'),
    'ne': () => import('dayjs/locale/ne'),
    'nl-be': () => import('dayjs/locale/nl-be'),
    'nl': () => import('dayjs/locale/nl'),
    'nn': () => import('dayjs/locale/nn'),
    'oc-lnc': () => import('dayjs/locale/oc-lnc'),
    'pa-in': () => import('dayjs/locale/pa-in'),
    'pl': () => import('dayjs/locale/pl'),
    'pt-br': () => import('dayjs/locale/pt-br'),
    'pt': () => import('dayjs/locale/pt'),
    'rn': () => import('dayjs/locale/rn'),
    'ro': () => import('dayjs/locale/ro'),
    'ru': () => import('dayjs/locale/ru'),
    'rw': () => import('dayjs/locale/rw'),
    'sd': () => import('dayjs/locale/sd'),
    'se': () => import('dayjs/locale/se'),
    'si': () => import('dayjs/locale/si'),
    'sk': () => import('dayjs/locale/sk'),
    'sl': () => import('dayjs/locale/sl'),
    'sq': () => import('dayjs/locale/sq'),
    'sr-cyrl': () => import('dayjs/locale/sr-cyrl'),
    'sr': () => import('dayjs/locale/sr'),
    'ss': () => import('dayjs/locale/ss'),
    'sv-fi': () => import('dayjs/locale/sv-fi'),
    'sv': () => import('dayjs/locale/sv'),
    'sw': () => import('dayjs/locale/sw'),
    'ta': () => import('dayjs/locale/ta'),
    'te': () => import('dayjs/locale/te'),
    'tet': () => import('dayjs/locale/tet'),
    'tg': () => import('dayjs/locale/tg'),
    'th': () => import('dayjs/locale/th'),
    'tk': () => import('dayjs/locale/tk'),
    'tl-ph': () => import('dayjs/locale/tl-ph'),
    'tlh': () => import('dayjs/locale/tlh'),
    'tr': () => import('dayjs/locale/tr'),
    'tzl': () => import('dayjs/locale/tzl'),
    'tzm-latn': () => import('dayjs/locale/tzm-latn'),
    'tzm': () => import('dayjs/locale/tzm'),
    'ug-cn': () => import('dayjs/locale/ug-cn'),
    'uk': () => import('dayjs/locale/uk'),
    'ur': () => import('dayjs/locale/ur'),
    'uz-latn': () => import('dayjs/locale/uz-latn'),
    'uz': () => import('dayjs/locale/uz'),
    'vi': () => import('dayjs/locale/vi'),
    'x-pseudo': () => import('dayjs/locale/x-pseudo'),
    'yo': () => import('dayjs/locale/yo'),
    'zh-cn': () => import('dayjs/locale/zh-cn'),
    'zh-hk': () => import('dayjs/locale/zh-hk'),
    'zh-tw': () => import('dayjs/locale/zh-tw'),
    'zh': () => import('dayjs/locale/zh'),
    'es-pr': () => import('dayjs/locale/es-pr'),
    'es-us': () => import('dayjs/locale/es-us'),
    'es': () => import('dayjs/locale/es')
};
