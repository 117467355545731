export const getAnnualSaving = (plan, plans, returnAmount = false) => {
    if (!plans) return;
    const altPlan = plans.find(p => p.duration !== plan.duration && p.users === plan.users && p.title === plan.title);
    const annualPlan = plan.duration === 'P1Y' ? plan : altPlan;
    const monthlyPlan = plan.duration === 'P1M' ? plan : altPlan;
    if (!annualPlan || !monthlyPlan) return;
    const annualEquivalent = monthlyPlan.amount * 12;
    return returnAmount
        ? ((annualEquivalent - annualPlan.amount) / 100).toFixed(0)
        : Math.round((1 - (annualPlan.amount / annualEquivalent)) * 100);
};

export const CURRENT_PLANS = [
    'PRO_10_Monthly_Q2_2022', 'PRO_20_Yearly_Q2_2022', 'PRO_50_Yearly_Q2_2022',
    'PRO_100_Yearly_Q2_2022', 'PRO_150_Yearly_Q2_2022', 'PRO_200_Yearly_Q2_2022',
    'PRO_250_Yearly_Q2_2022', 'PRO_UNLIMITED_Q2_2022', 'PREMIUM_20_Yearly_Q2_2022',
    'PREMIUM_50_Yearly_Q2_2022', 'PREMIUM_100_Yearly_Q2_2022', 'PREMIUM_150_Yearly_Q2_2022',
    'PREMIUM_200_Yearly_Q2_2022', 'PREMIUM_250_Yearly_Q2_2022', 'PREMIUM_UNLIMITED_Q2_2022',
    'PRO_Q4_2022', 'PREMIUM_Q4_2022'
];
export const PURCHASABLE_PLANS = [ 'PRO_Q4_2022', 'PREMIUM_Q4_2022' ];

export const isLegacy = plan => {
    return !CURRENT_PLANS.includes(plan);
};
export const isPurchasable = plan => {
    return CURRENT_PLANS.includes(plan);
};
