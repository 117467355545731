export default {
    beforeMount(el, binding) {
        const callback = binding.value;
        el.$observer = new ResizeObserver(callback);
    },
    mounted(el) {
        el.$observer.observe(el, { attributes: true });
    },
    unmounted(el) {
        if (el.$observer) el.$observer.disconnect();
    }
};
