import { ref, readonly } from 'vue';
import { hexToRgb, rgbToHex, rgbToHsl, hslToHex, readable, mix, shade1, shade2, shadow } from '@/helpers/colour';

// 0.179 is a11y suggestion but we need it to be this high so
// that the contrast of `warning` returns the lighter colour. 🤫
export let READABLE_BREAKPOINT = 0.612;
export const COLOUR_DARK = '#0F171F';
export const COLOUR_LIGHT = '#FFFFFF';

const _theme = { colours: {} };
export const DEFAULT_COLOURS = {
    theme: '#446CFC',
    secondary: '#151F6D',
    complimentary: '#F73BA0',
    info: '#E6FDFF',
    success: '#00A88F',
    warning: '#FED141',
    danger: '#F9423A',
    headerbg: '#FFFFFF',
    headersecondary: '#0F171F',

    cloud: '#F6F7F8',
    smoke: '#EDEFF1',
    mercury: '#DCDFE3',
    steel: '#CACED5',
    anchor: '#919AA8',
    asphalt: '#7D8288',
    night: '#1C252E',
    coal: '#0F171F'
};
export const THEME_COLOURS = { ...DEFAULT_COLOURS, ...(_theme.colours || {}) };

export const DEFAULT_TILE_COLOURS = {
    interactive:    '#DCDFE3',
    textImg:        '#DCDFE3',
    video:          '#DCDFE3',
    pictureExample: '#DCDFE3',
    damageControl:  '#DCDFE3',
    checkpoint:     '#DCDFE3',
    challenge:      '#DCDFE3',
    branchingScenario: '#DCDFE3'
};
export const TILE_COLOURS = { ...DEFAULT_TILE_COLOURS, ...(_theme.tile_types || {}) };

export const COLOURS_TO_GENERATE = [
    ['primary', 'theme'],
    ['secondary', 'secondary'],
    ['complimentary', 'complimentary'],
    ['success', 'success'],
    ['warning', 'warning'],
    ['danger', 'danger'],
];

const theme = ref(_theme);

const set = updated => theme.value = { ...theme.value, ...updated };
const setContrastBreakpoint = breakpoint => READABLE_BREAKPOINT = breakpoint;
const generate = (clientTheme, breakpoint = READABLE_BREAKPOINT) => {
    const clientColours = clientTheme && clientTheme.colours || {};
    const colours = { ...DEFAULT_COLOURS, ...clientColours };
    theme.value = { ...clientTheme, colours };

    const setProperties = (name, properties) => {
        let rules = [];
        properties.forEach(([suffix, value]) => {
            const propertyName = `${name}${suffix ? `-${suffix}` : ''}`;
            colours[propertyName] = value;
            rules.push(`--${propertyName}: ${value};`);
        });
        return rules;
    };

    const lightRgb = hexToRgb(COLOUR_LIGHT);

    const allRules = [];
    COLOURS_TO_GENERATE.forEach(([name, key]) => {
        const hex = colours[key];
        const rgb = hexToRgb(hex);
        const hsl = rgbToHsl(...rgb);
        const contrastHex = readable(rgb, breakpoint, COLOUR_DARK, COLOUR_LIGHT).contrast;
        const contrastRgb = hexToRgb(contrastHex);
        const tintRgb = mix(rgb, lightRgb, 0.1);
        const tintHex = rgbToHex(tintRgb);
        const contrastTint = readable(tintRgb, breakpoint, COLOUR_DARK, COLOUR_LIGHT).contrast;
        const contrastTintRgb = hexToRgb(contrastTint);
        const rules = setProperties(name, [
            [null, hex],
            ['rgb', rgb],
            ['tint', tintHex],
            ['tint-2', hslToHex(hsl[0], hsl[1], hsl[2] + 20)],
            ['tint-rgb', tintRgb],
            ['shade-1', shade1(rgb)],
            ['shade-2', shade2(rgb)],
            ['fade-1', `rgba(${rgb}, 0.8)`],
            ['fade-2', `rgba(${rgb}, 0.2)`],
            ['contrast', contrastHex],
            ['contrast-rgb', contrastRgb],
            ['contrast-lessen-1', rgbToHex(mix(contrastRgb, rgb, 0.88))],
            ['contrast-fade-1', `rgba(${contrastRgb}, 0.8)`],
            ['contrast-tint-1', `rgba(${contrastTintRgb}, 0.8)`],
            ['shadow', shadow(hex) ]
        ]);
        allRules.push(...rules);
    });
    createRootStyle(allRules);
    if (theme.value.custom_less) createCustomCssStyle(theme.value.custom_less);
};

const loadedFonts = [];

export const loadFont = font => {
    if (!font) return;
    if (loadedFonts.includes(font)) return;
    const param = font.split(' ').join('+');
    const tag = document.createElement('link');
    tag.rel = 'stylesheet';
    tag.href = `https://fonts.googleapis.com/css2?family=${param}:wght@400;600;700&display=swap`;
    document.body.append(tag);
    loadedFonts.push(font);
};

const createRootStyle = (rules) => {
    const cssString = `:root{${rules.join('')}}`;
    const el = document.createElement('style');
    el.type = 'text/css';
    el.textContent = cssString;
    document.head.appendChild(el);
};

const createCustomCssStyle = (css) => {
    const el = document.createElement('style');
    el.type = 'text/css';
    el.textContent = css;
    document.head.appendChild(el);
};

// These colours were referenced with numbers in the old templates and were
// generated with client specific Less. They have been added here to allow
// maintaining backwards compatibility whilst keeping them dynamic.
export let legacyColourSlices;
if (theme.value.tile_types) {
    legacyColourSlices = {
        1: theme.value.colours.theme,
        2: theme.value.colours.secondary,
        3: theme.value.tile_types.audio,
        4: theme.value.tile_types.checkpoint,
        5: theme.value.colours.success,
        6: theme.value.tile_types.pictureExample,
        7: theme.value.colours.complimentary,
        8: theme.value.tile_types.additional3,
        9: theme.value.tile_types.roomExplorer,
        10: theme.value.tile_types.challenge,
        11: theme.value.tile_types.animation,
        12: theme.value.tile_types.damageControl
    };
}

export default readonly({
    set,
    generate,
    setContrastBreakpoint,
    theme
});
