import { easeInOutQuad } from '@/helpers/animations';
import { COLOUR_DARK, COLOUR_LIGHT, READABLE_BREAKPOINT } from '@/store/theme';

export const hexToRgb = hex => {
    if (!hex) return;
    const normalised = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b).substring(1);
    if (normalised.length < 6) return;
    return normalised.match(/.{2}/g).map(x => parseInt(x, 16));
};

export const hexToHsl = hex => {
    const rgb = hexToRgb(hex);
    if (!rgb || rgb === hex) return;
    return rgbToHsl(...rgb);
};

export const rgbToHsl = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;

    let h = 0, s = 0, l = 0;
    let cmin = Math.min(r, g, b), cmax = Math.max(r, g, b);
    const delta = cmax - cmin;

    if (delta === 0) {
        h = 0;
    } else if (cmax === r) {
        h = ((g - b) / delta) % 6;
    } else if (cmax === g) {
        h = (b - r) / delta + 2;
    } else {
        h = (r - g) / delta + 4;
    }

    h = Math.round(h * 60);
    if (h < 0) h += 360;

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return [h, s, l];
};

export const hslToHex = (h, s, l) => {
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s,
        x = c * (1 - Math.abs((h / 60) % 2 - 1)),
        m = l - c/2,
        r = 0,
        g = 0,
        b = 0;

    if (0 <= h && h < 60) {
        r = c; g = x; b = 0;
    } else if (60 <= h && h < 120) {
        r = x; g = c; b = 0;
    } else if (120 <= h && h < 180) {
        r = 0; g = c; b = x;
    } else if (180 <= h && h < 240) {
        r = 0; g = x; b = c;
    } else if (240 <= h && h < 300) {
        r = x; g = 0; b = c;
    } else if (300 <= h && h < 360) {
        r = c; g = 0; b = x;
    }

    r = Math.round((r + m) * 255).toString(16);
    g = Math.round((g + m) * 255).toString(16);
    b = Math.round((b + m) * 255).toString(16);

    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;

    return '#' + r + g + b;
};

export const rgbToHex = rgb => {
    if (!rgb) return;
    const [r, g, b] = rgb;
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
};


export const readable = (rgb, breakpoint = READABLE_BREAKPOINT, forLight = COLOUR_DARK, forDark = COLOUR_LIGHT) => {
    if (!rgb) return {};

    const [r, g, b] = Object.keys(rgb).map(key => {
        const channel = rgb[key] / 255;
        return channel <= 0.03928 ? channel / 12.92 : ((channel + 0.055) / 1.055) ** 2.4;
    });
    const isLight = parseFloat((0.2126 * r + 0.7152 * g + 0.0722 * b).toFixed(3)) > breakpoint;
    return {
        isLight,
        contrast: isLight ? forLight : forDark,
        similar: isLight ? forDark : forLight
    };
};

export const mix = (rgb1, rgb2, weight = 0.5, alpha = false) => {
    if (!rgb1 || !rgb2) return;

    const w = weight * 2 - 1;
    const w1 = (w + 1) / 2.0;
    const w2 = 1 - w1;

    let colour = [
        Math.round(rgb1[0] * w1 + rgb2[0] * w2),
        Math.round(rgb1[1] * w1 + rgb2[1] * w2),
        Math.round(rgb1[2] * w1 + rgb2[2] * w2)
    ];

    if (alpha) {
        const alpha1 = isNaN(rgb1[3]) ? 1 : rgb1[3];
        const alpha2 = isNaN(rgb2[3]) ? 1 : rgb2[3];
        colour.push(alpha1 * w1 + alpha2 * w2);
    }

    return colour;
};

export const shadow = hex => {
    const colourRgb = hexToRgb(hex);
    if (!colourRgb || colourRgb === hex) return;
    const rgb = colourRgb.join(',');
    return `
        0px 0px 1px rgb(${rgb}, 0.04),
        0px 1px 6px rgba(${rgb}, 0.12),
        0px 5px 20px rgba(${rgb}, 0.18)
    `;
};

export const spin = (hsl, amount) => {
    const [h, s, l] = hsl.split(',').map(Number);
    let hue = (h + amount) % 360;
    if (hue < 0) hue = hue + 360;
    return [hue, s, l].join(',');
};

const ANALOGOUS_RANGE = 90;
export const analogous = (base, length = 3, isMirrored = false) => {
    const increment = ANALOGOUS_RANGE / (length - 1);
    const colour = (hexToHsl(base) || []).join(',');
    if (!colour) return;
    const colours = Array.from({ length }, (_, index) => {
        const amount = increment * index;
        return hslToHex(...spin(colour, amount).split(',').map(Number));
    });
    if (isMirrored) return colours.concat(colours.slice(1, -1).reverse());
    return colours;
};

export const shade1 = (rgb, dark = COLOUR_DARK) => {
    const darkRgb = hexToRgb(dark);
    if (!rgb) return;
    return rgbToHex(mix(rgb, darkRgb, 0.88));
};

export const shade2 = (rgb, dark = COLOUR_DARK) => {
    const darkRgb = hexToRgb(dark);
    if (!rgb) return;
    return rgbToHex(mix(rgb, darkRgb, 0.6));
};

export const shade3 = (rgb, dark = COLOUR_DARK) => {
    const darkRgb = hexToRgb(dark);
    if (!rgb) return;
    return rgbToHex(mix(rgb, darkRgb, 0.45));
};

// Create a gradient that has its stops eased to appear smoother
export const createEasedGradient = options => {
    const easing = options.easing || easeInOutQuad;
    const resolution = options.resolution || 10;
    const type = options.type || 'linear';
    const startOffset = options.startOffset || 0;
    const endOffset = options.endOffset || 0;
    // angle acts as position if type is 'radial'
    const angle = options.angle || 'to right';

    const ramp = (1 - endOffset) - startOffset;

    let stops = [];

    for (let i = 0; i <= resolution; i++) {
        const decimal = i / resolution;
        const position = (decimal * ramp) + startOffset;

        const eased = easing(decimal);
        const colour = mix(options.endColour, options.startColour, eased, true);
        const percentage = `${position * 100}%`;

        stops.push(`rgba(${colour}) ${percentage}`);
    }

    if (type === 'linear') {
        return `linear-gradient(${angle}, ${stops})`;
    } else if (type === 'radial') {
        return `radial-gradient(${angle}, ${stops})`;
    }
};
