export const animate = (to, from, callback, { duration = 1000, curve = 'linear' }) => {
    const start = performance.now();
    const difference = to - from;
    const curveFunction = typeof curve === 'string' ? curves[curve] : curve;

    return new Promise(resolve => {
        const update = time => {
            let timeFraction = (time - start) / duration;
            if (timeFraction > 1) {
                timeFraction = 1;
            }

            const progressFraction = curveFunction(timeFraction);
            const progress = from + (progressFraction * difference);

            const shouldStop = timeFraction >= 1;
            const shouldCancel = callback(progress, shouldStop);

            if (shouldStop || (shouldCancel === true)) {
                resolve();
                return;
            }

            window.requestAnimationFrame(update);
        };

        window.requestAnimationFrame(update);
    });
};

const C1 = 1.70158;
const C2 = C1 * 1.525;
const C3 = C1 + 1;
const C4 = (2 * Math.PI) / 3;
export const linear = x => x;

export const createEaseIn = power => x => Math.pow(x, power);
export const createEaseOut = power => x => 1 - Math.abs(Math.pow(x - 1, power));
export const createEaseInOut = power => x => x < 0.5 ? createEaseIn(power)(x * 2) / 2 : createEaseOut(power)(x * 2 - 1) / 2 + 0.5;

export const easeInQuad = createEaseIn(2);
export const easeOutQuad = createEaseOut(2);
export const easeInOutQuad = createEaseInOut(2);
export const easeInCubic = createEaseIn(3);
export const easeOutCubic = createEaseOut(3);
export const easeInOutCubic = createEaseInOut(3);
export const easeInQuart = createEaseIn(4);
export const easeOutQuart = createEaseOut(4);
export const easeInOutQuart = createEaseInOut(4);
export const easeInQuint = createEaseIn(5);
export const easeOutQuint = createEaseOut(5);
export const easeInOutQuint = createEaseInOut(5);

export const easeOutElastic = x => {
    if (x === 0 || x === 1) return x;
    return Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * C4) + 1;
};
export const easeInElastic = x => {
    if (x === 0 || x === 1) return x;
    return -Math.pow(2, 10 * x - 10) * Math.sin((x * 10 - 10.75) * C4);
};
export const easeInBack = x => {
    return C3 * x * x * x - C1 * x * x;
};
export const easeOutBack = x => {
    return 1 + C3 * Math.pow(x - 1, 3) + C1 * Math.pow(x - 1, 2);
};
export const easeInOutBack = x => {
    return x < 0.5
        ? (Math.pow(2 * x, 2) * ((C2 + 1) * 2 * x - C2)) / 2
        : (Math.pow(2 * x - 2, 2) * ((C2 + 1) * (x * 2 - 2) + C2) + 2) / 2;
};
export const curves = {
    linear,
    easeInQuad,
    easeOutQuad,
    easeInOutQuad,
    easeInCubic,
    easeOutCubic,
    easeInOutCubic,
    easeInQuart,
    easeOutQuart,
    easeInOutQuart,
    easeInQuint,
    easeOutQuint,
    easeInOutQuint,
    easeOutElastic,
    easeInElastic,
    easeInBack,
    easeOutBack,
    easeInOutBack
};
