<template>
    <PlyrIcons />
    <ClientOnly>
        <ExperimentControls />
        <div v-if="isImpersonating" class="impersonating">
            <Clickable @click="stopImpersonating">
                <Icon name="user" size="16" />
                <Txt size="marker">
                    End impersonation session
                </Txt>
            </Clickable>
        </div>
    </ClientOnly>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue';
import { analogous, hexToRgb } from '@/helpers/colour';
import { loadScriptHtml, loadScriptTag } from '@/helpers/utilities';

import Txt from '@/components/form/txt';
import Icon from '@/components/utility/icon';
import Clickable from '@/components/utility/clickable';
import PlyrIcons from '@/components/utility/plyr-icons';

const ExperimentControls = defineAsyncComponent(() => import('@/components/utility/experiment-controls'));

const colours = analogous('#446CFC', 6, true);
const getColour = index => colours[index % colours.length];
const getRGB = colour => hexToRgb(colour).join(',');

const PAID_TIERS = ['solo', 'team', 'business', 'enterprise', 'headless', 'readytech'];

export default  {
    name: 'App',
    components: { Clickable, Txt, Icon, PlyrIcons, ExperimentControls },
    provide() {
        return {
            $stripe: computed(() => this.stripe),
            $global: computed(() => ({
                user: this.user,
                client: this.client,
                clients: this.clients,
                token: this.token,
                hasUserLimit: this.client && this.client.userLimit && this.client.userLimit > 0,
                isOwner: this.user && this.client && this.user.id === this.client.ownerId,
                isLoggedIn: this.isLoggedIn,
                isHeadless: this.isHeadless,
                isPaid: this.client && PAID_TIERS.includes(this.client.tier),
                isProduction: this.$config.public.appEnv === 'prod' || this.$config.public.appEnv === 'enterprise',
                isEnterprise: this.$config.public.appEnv === 'enterprise',
                flags: {
                    readytech: this.client && this.client.tier === 'readytech'
                        || this.$route.path.includes('/redeem')
                        || this.$route.path.includes('/signin/readytech')
                },
                hasPlan: this.hasPlan,
                get: this.getGlobalData,
                set: this.setGlobalData,
                checkSession: this.checkSession,
                setClient: this.setGlobalClient,
                setSurvey: this.setSurvey,
                loadStripe: this.loadStripe
            })),
            $browser: computed(() => ({
                userAgent: this.ua,
                is: this.ua ? {
                    ios: /ipad|iphone|ipod/.test(this.ua) || /ipad|iphone|ipod/.test(this.platform),
                    safari: this.ua.includes('safari') && !this.ua.includes('chrome'),
                    firefox: this.ua.includes('firefox'),
                    touch: window && 'ontouchstart' in window,
                    chrome: window && window.chrome,
                    osx: /(macintosh|macintel|macppc|mac68k|macos)/.test(this.ua) || this.platform.includes('mac'),
                    windows: /(win32|win64|windows|wince)/.test(this.ua) || this.platform.includes('win')
                } : {}
            })),
        };
    },
    data() {
        return {
            ua: null,
            platform: null,

            user: null,
            client: null,
            token: null,
            clients: null,
            stripe: null,

            isLoggingIn: false,
            isIntercomLoaded: false,
            isIntercomBooted: false
        };
    },
    computed: {
        isLoggedIn() {
            return !!(this.user && this.client);
        },
        isHeadless() {
            return !!(this.client && this.token && this.client.actions && this.client.actions.headless_embed);
        },
        isImpersonating() {
            return this.user && this.user.impersonating;
        }
    },
    watch: {
        '$route.path': {
            handler() {
                if (this.isLoggedIn) this.$nextTick(() => this.$track.page());
            }
        }
    },
    mounted() {
        this.$track.globalProperties = {
            'version': '1.6.5'
        };
        this.$track.isEnterprise = this.$config.public.appEnv === 'enterprise';
        this.getBrowserData();
        this.cacheQueryParameters();
        
        this.loadIntercom();
        this.loadSegment();
        this.loadJam();

        window.addEventListener('click', this.handleGenericEvents);
    },
    methods: {
        handleGenericEvents({ target }) {
            if (target.tagName !== 'A' || target.tagName !== 'BUTTON') target = target.closest('a, button');
            if (!target) return;
            if (target.dataset.customHandler) return;
            const href = target && target.href || '';
            let event = '';
            if (href.includes('/build')) event = 'Builder Opened';
            if (href.includes('/dashboard')) event = 'App Opened';
            if (href.includes('/pricing') && !href.includes('stripe.com')) event = 'Pricing Page Opened';
            if (href.includes('/headless')) event = 'Headless Page Opened';
            if (href.includes('/contact-us')) event = 'Enquiry Form Opened';
            if (href.includes('/studio')) event = 'Studio Services Page Opened';
            if (href.includes('/signin')) event = 'Sign In Started';
            if (href.includes('/help')) {
                event = 'Knowledge Base Opened';
            }
            if (event) {
                this.$track.event(event, {
                    'button_cta': target.innerText,
                    'button_placement': target.dataset.placement,
                    'collection_name': target.dataset.collection,
                    'context': target.dataset.context,
                    'trigger_context': target.dataset.context,
                });
            }
        },
        getBrowserData() {
            this.ua = navigator && navigator.userAgent && navigator.userAgent.toLowerCase();
            this.platform = navigator && navigator.userAgentData && navigator.userAgentData.platform.toLowerCase() || navigator && navigator.platform && navigator.platform.toLowerCase();
        },
        cacheQueryParameters() {
            const query = new URLSearchParams(window.location.search);
            if (query.has('flow')) {
                localStorage.setItem('flow', query.get('flow'));
                window.history.replaceState({}, document.title, window.location.pathname + window.location.hash);
            }
        },
        loadJam() {
            const layout = this.$route.meta && this.$route.meta.layout;
            if (layout !== 'public') {
                loadScriptTag('https://js.jam.dev/support/a3643867-2164-41b7-b538-b2f8dca19b1c.js');
            }
        },
        loadStripe(override = false, params = {}) {
            return new Promise((resolve) => {
                const layout = this.$route.meta && this.$route.meta.layout;
                if (layout !== 'secured' && !override) return resolve();
                loadScriptTag('https://js.stripe.com/v3/', () => {
                    this.stripe = window.Stripe(this.$config.public.stripeKey, params);
                    resolve();
                });
            });
        },
        loadIntercom() {
            const layout = this.$route.meta && this.$route.meta.layout;
            if (this.$config.public.intercomKey && layout !== 'public') {
                loadScriptHtml(`(function(){
                    var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${this.$config.public.intercomKey}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}
                })();`, () => {
                    this.isIntercomLoaded = true;
                    this.$nextTick(() => this.bootIntercom());
                });
            }
        },
        loadSegment() {
            const layout = this.$route.meta && this.$route.meta.layout;
            if (this.$config.public.segmentKey && layout !== 'public') {
                loadScriptHtml(`!function(){
                    var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="vVkKgwlcE7HMgyQ3I1TFOKZoPvzBpYZt";;analytics.SNIPPET_VERSION="4.15.3";
                    analytics.load("${this.$config.public.segmentKey}");
                }}();`, this.setupMixpanel);
            }
        },
        setupMixpanel() {
            window.analytics.ready(() => {
                if (window.mixpanel) {
                    window.mixpanel.set_config({
                        'record_block_selector': 'video',
                        'record_mask_text_selector': '.field.password input',
                        'record_collect_fonts': true,
                        'record_sessions_percent': 100
                    });
                    window.mixpanel.start_session_recording();
                }
                window.analytics.addSourceMiddleware(({ payload, next }) => {
                    if (payload.type === 'track' || payload.type === 'page') {
                        if (window.mixpanel) {
                            const segmentDeviceId = payload.obj.anonymousId;
                            window.mixpanel.register({ '$device_id': segmentDeviceId, 'distinct_id': `$device:${segmentDeviceId}` });
                            payload.obj.properties = {
                                ...payload.obj.properties,
                                ...window.mixpanel.get_session_recording_properties()
                            };
                        }
                    }
                    next(payload);
                });

                // HACK: Listen for Segment group calls and forward events to Mixpanel specific methods
                window.analytics.on('group', (event, properties) => {
                    if (!(window.mixpanel && window.mixpanel.get_group)) return;
                    window.mixpanel.get_group('workspace_id', event).set(properties);
                });
            });
        },
        loadCookieConsent() {
            const preventCookie = this.$route.meta.cookie === false;
            const layout = this.$route.meta && this.$route.meta.layout;
            if (preventCookie || layout !== 'cms' || layout !== 'default') return;
            loadScriptTag('https://cmp.osano.com/169kHMTqRiT6d6UJj/7cff1750-71f9-459a-98b6-8124a6e83d48/osano.js');
        },
        getGlobalData(initial = true) {
            this.isLoggingIn = true;
            return this.$http.get('/user')
                .then(({ data }) => {
                    this.isLoggingIn = false;
                    if (data.client) {
                        if (data.client.cors) data.client.cors.forEach((origin, index) => origin.colour = getColour(index));
                        data.client.colour = getRGB(getColour(data.clients.findIndex(c => c.id === data.client.id)));
                    }
                    if (data.clients) {
                        data.clients.forEach((client, index) => client.colour = getRGB(getColour(index)));
                        this.clients = data.clients;
                        this.$track.clients = data.clients;
                    }

                    this.setGlobalData(data.user, data.client, data.token, initial);

                    return data;
                })
                .catch(() => {
                    this.isLoggingIn = false;
                    this.setGlobalData(null, null, null, initial);
                })
                .finally(() => {
                    if (this.$config.public.appEnv === 'enterprise' && !this.$route.path.includes('/signin')) {
                        if (this.$route.meta.layout === 'secured' || this.$route.meta.layout === 'public' || this.$route.meta.layout === 'internal') return;
                        window.location.href = this.isLoggedIn ? '/dashboard/courses' : `https://coassemble.com${this.$route.fullPath}`;
                    }
                });
        },
        setGlobalData(user, client, token, initial = false) {
            this.user = user;
            this.client = client;
            this.token = token;
            this.$track.globalProperties['is_logged_in'] = !!user;
            this.$track.set(user, client);
            if (initial) {
                if (user && client && !user.lmsUser) {
                    this.$track.identify();
                }
                this.bootIntercom();
                this.$track.page();
            }
            if (this.isHeadless) {
                this.loadEntlaqaStyles();
                this.$http.defaults.headers.common['X-Client-Id'] = this.token;
            }
            if (this.token) {
                this.$http.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
            }
        },
        loadEntlaqaStyles() {
            // HACK: Stylesheet for Entlaqa
            if (this.client && this.client.id === 42288) {
                const link = document.createElement('link');
                link.rel = 'stylesheet';
                link.href = 'https://jadarat-cdn.vercel.app/coassemble/styles.css';
                document.head.appendChild(link);
            }
        },
        bootIntercom() {
            if (this.isLoggingIn) return;
            if (this.isIntercomBooted) return;
            if (!this.isIntercomLoaded) return;
            if (window.Intercom) {
                if (this.isLoggedIn) {
                    window.Intercom('boot', {
                        'api_base': 'https://api-iam.intercom.io',
                        'app_id': this.$config.public.intercomKey,
                        'name': this.user.name,
                        'email': this.user.email,
                        'user_id': this.user.id,
                        'created_at': this.user.created,
                        'user_hash': this.user.userHash
                    });
                } else {
                    window.Intercom('boot', {
                        'api_base': 'https://api-iam.intercom.io',
                        'app_id': this.$config.public.intercomKey
                    });
                }
                this.isIntercomBooted = true;
            }
        },
        setGlobalClient(client, group = false) {
            client.colour = getRGB(getColour(this.clients.findIndex(c => c.id === client.id)));
            this.client = client;
            this.$track.set(null, client);
            if (group) this.$track.group();
        },
        setSurvey(survey) {
            if (JSON.stringify(this.client.survey) === JSON.stringify(survey)) return;
            return this.$http.post('/client/survey', survey)
                .then(({ data }) => {
                    this.client.survey = survey;
                    return data;
                });
        },
        checkSession(error) {
            if (this.isHeadless) return this.postMessage({ type: 'session', status: error.status === 401 ? 'expired' : 'error' });
            return this.getGlobalData(false)
                .then(() => {
                    if (!this.isLoggedIn) this.$router.push('/signin?signout=true');
                })
                .catch(() => {
                    this.$router.push('/signin?signout=true');
                });
        },
        postMessage(data) {
            if (window.self !== window.top) window.parent.postMessage(JSON.stringify(data), '*');
        },
        hasPlan(tier) {
            if (!this.client) return false;
            if (this.isHeadless) return true;
            if (tier === 'solo') return ['solo', 'team', 'business', 'enterprise', 'readytech', 'headless'].includes(this.client.tier);
            if (tier === 'team') return ['team', 'business', 'enterprise', 'readytech', 'headless'].includes(this.client.tier);
            if (tier === 'business') return ['business', 'enterprise', 'readytech', 'headless'].includes(this.client.tier);
            return tier === this.client.tier;
        },
        stopImpersonating() {
            return this.$http.post(`/admin/user/${this.user.id}/impersonate/stop`)
                .then(() => this.$router.push('/admin'));
        }
    }
};
</script>

<style lang="less">
@import '@/less/global.less';
@import '@/less/global.author.less';


.impersonating {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 8px solid var(--danger);
    outline-offset: -8px;
    filter: drop-shadow(0px 0px 1px rgba(var(--danger-rgb), 0.04)) drop-shadow(0px 0px 2px rgba(var(--danger-rgb), 0.06)) drop-shadow(0px 2px 8px rgba(var(--danger-rgb), 0.04));
    pointer-events: none;
    color: var(--danger-contrast);
    button {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        border-radius: 0 0 8px 8px;
        padding: 12px 16px;
        background: var(--danger);
        gap: 4px;
        pointer-events: auto;
        svg {
            width: 96px;
        }
        &:before, &:after {
            content: '';
            position: absolute;
            top: 8px;
            width: 8px;
            height: 8px;
            background: var(--danger);
            mask-image: radial-gradient(circle 14px at 0 0, transparent 50%, black 50%);
        }
        &:before {
            left: -8px;
            transform: rotate(-90deg);
        }
        &:after {
            right: -8px;
            transform: rotate(180deg);
        }
    }
}
</style>
