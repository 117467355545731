export const shuffle = function(array) {
    const response = array.slice();
    for (let i = 0; i < response.length; i++) {
        const j = Math.floor(Math.random() * (i + 1));
        [response[i], response[j]] = [response[j], response[i]];
    }
    return response;
};

export const derange = function(array) {
    if (array.length < 2) return array; // No derangements possible

    let response;
    do {
        response = shuffle(array);
    } while (response.some((value, i) => value === array[i]));

    return response;
};

export const hasRichText = text => Boolean(text) && (text !== '<p></p>');

export const timeout = function(callback, ms) {
    let id;
    const timer = new Promise(resolve => id = setTimeout(resolve, ms)).then(callback);
    timer.cancel = () => clearTimeout(id);
    return timer;
};

export const cancel = function(timer) {
    if (timer && timer.cancel) timer.cancel();
};

export const debounce = function(callback, ms, timer = null) {
    cancel(timer);
    const _timer = timeout(() => callback(_timer), ms);
    return _timer;
};

export const throttle = function(func) {
    let queuedCallback;
    return (...args) => {
        if (!queuedCallback) {
            requestAnimationFrame(() => {
                const cb = queuedCallback;
                queuedCallback = null;
                cb(...args);
            });
        }
        queuedCallback = func;
    };
};

export const sum = function(arr) {
    return arr.reduce((a, b) => a + b, 0);
};

export const sumBy = function(obj, key) {
    return sum(obj.map(x => x && Number(x[key])).filter(Boolean));
};

export const groupBy = function(arr, key) {
    return arr.reduce((r, v, i, a, k = v[key]) => ((r[k] || (r[k] = [])).push(v), r), {});
};

export const copy = obj => {
    if (!obj) return obj;
    if (Object(obj) !== obj) return obj;

    let result = Array.isArray(obj) ? [] : {};

    let value;
    for (const key in obj) {
        if (Object.is(obj[key], obj)) continue;
        value = obj[key];
        result[key] = (typeof value === 'object') ? copy(value) : value;
    }

    return result;
};

export const isCompletable = function(completable) {
    const options = window.client && window.client.options || {};
    return [ false, true, (options.show_progress_markers && options.enforce_presentation_completion === true) ][completable % 3];
};

export const sanitise = function(html, preserveLineBreaks = false) {
    if (!html || typeof html !== 'string') return html;

    // Replace <br>, </p> and </ul> with newline character
    if (preserveLineBreaks) html = html.replace(/<(br\s*\/*|\/p|\/ul|\/ol)>/gm, '\n\n');

    return html
        .replace(/<(em|\/em|strong|\/strong|p)>/gm, '') // Convert inline tags to empty strings
        .replace(/<(?:.|\n)*?>/gm, ' ')                 // Convert HTML to whitespace
        .replace(/&amp;/g, '&')
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .replace(/&nbsp;/g, ' ')
        .replace(/[^\S\r\n]{2,}/g, ' ').trim();         // Clear excess whitespace
};

export const isVisible = function(el) {
    const { top, left, bottom, right, width, height } = el.getBoundingClientRect();
    return top >= 0
        && left >= 0
        && bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && right <= (window.innerWidth || document.documentElement.clientWidth)
        && !(width === 0 && height === 0);
};

export const isPartiallyVisible = function(el) {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return top < innerHeight && bottom >= 0 && left < innerWidth && right >= 0;
};

export const isContentOverflowing = function(el, times = 1) {
    const x = el.scrollWidth > (el.clientWidth * times); // Times to check how many lines worth for text.
    const y = el.scrollHeight > (el.clientHeight * times);
    return { x, y, both: x && y, either: x || y };
};

export const simplifyType = function(type) {
    if (!type) return '';
    if (type.includes('pdf')) {
        return 'pdf';
    } else if (type.includes('spreadsheet')) {
        return 'excel';
    } else if (type.includes('document')) {
        return 'word';
    } else if (type.includes('image')) {
        return 'image';
    } else if (type.includes('audio')) {
        return 'audio';
    } else if (type.includes('video')) {
        return 'video';
    } else if (type.includes('zip')) {
        return 'archive';
    } else {
        return '';
    }
};

export const fraction = function(decimal, tolerance = 0.001) {
    // Return whole numbers immediately
    if (decimal % 1 === 0) return { numerator: decimal, denominator: 1 };

    const original = decimal;
    let iteration = 0, denominator = 1, last = 0, numerator;
    while (iteration < 20) {
        decimal = 1 / (decimal - Math.floor(decimal));
        const _denominator = denominator;
        denominator = Math.floor(denominator * decimal + last);
        last = _denominator;
        numerator = Math.ceil(original * denominator);

        if (Math.abs(numerator/denominator - original) < tolerance) break;

        iteration++;
    }

    return { numerator, denominator };
};

const isRooted = /^\//;
const isExternal = /^(?:[a-z]*:?\/{2})/i;
export const url = function(path, root = 'https://static.ecoach.com') {
    if (!path) return;
    if (path && path.$$unwrapTrustedValue) return path.$$unwrapTrustedValue();
    if (typeof path !== 'string') return path;
    if (path.includes('data:image')) return path;
    if (path.includes('../')) path = path.replace(/\.\.\//g, '');
    if (path.match(isExternal)) return path;
    return path.match(isRooted) ? `${root}${path}` : `${root}/${path}`;
};

export const numberToLetter = (number, alphabet = 'abcdefghijklmnopqrstuvwxyz') => {
    let letters = '';
    while (number >= 0) {
        letters = alphabet[number % alphabet.length] + letters;
        number = Math.floor(number / alphabet.length) - 1;
    }
    return letters;
};

// Run callbacks in sequence based on array. Callbacks should always return a promise.
export const sequence = (array, callback) => {
    const promise = Promise.resolve();
    if (!array || !array.length) return promise;
    return array.reduce((promise, ...args) => promise.then(() => callback(...args)), promise);
};

export const toPascalCase = str => str.replace(/(^\w|-\w)/g, str => str.replace(/-/, '').toUpperCase());
export const toKebabCase = (str) => {
    return str
        .replace(/\B([A-Z])(?=[a-z])/g, '-$1')
        .replace(/\B([a-z0-9])([A-Z])/g, '$1-$2')
        .toLowerCase();
};

export const copyToClipboard = text => {
    // Legacy
    if (!navigator.clipboard) {
        return new Promise((resolve, reject) => {

            const element = document.createElement('textarea');
            const previousElement = document.activeElement;

            element.value = text;
            element.setAttribute('readonly', ''); // Prevent keyboard from showing on mobile

            element.style.contain = 'strict';
            element.style.position = 'absolute';
            element.style.left = '-9999px';
            element.style.fontSize = '12pt'; // Prevent zooming on iOS

            const selection = document.getSelection();
            const originalRange = selection.rangeCount > 0 && selection.getRangeAt(0);

            document.body.append(element);
            element.select();

            element.selectionStart = 0; // Explicit selection workaround for iOS
            element.selectionEnd = text.length;

            let isSuccess = false;
            try {
                isSuccess = document.execCommand('copy');
            } catch {
                // Copying failed
            }

            element.remove();

            if (originalRange) {
                selection.removeAllRanges();
                selection.addRange(originalRange);
            }

            if (previousElement) previousElement.focus();

            if (!isSuccess) {
                reject();
            }
            resolve();
        });
    }

    return navigator.clipboard.writeText(text);
};

export const download = (data, type, filename) => {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const file = new File([blob], filename, { type });
    const href = window.URL.createObjectURL(file);
    downloadLink(href, filename);
    setTimeout(() => window.URL.revokeObjectURL(href), 1000);
};

export const downloadLink = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
};

export const loadScriptTag = (src, callback, attrs = {}) => {
    if (document.querySelector(`script[src="${src}"]`) && callback) return callback();
    const tag = document.createElement('script');

    tag.src = src;
    Object.keys(attrs).forEach(key => tag.setAttribute(key, attrs[key]));
    const bodyTag = document.getElementsByTagName('body')[0];
    bodyTag.parentNode.insertBefore(tag, bodyTag);
    tag.onload = callback;
};

export const loadScriptHtml = (html, callback) => {
    const tag = document.createElement('script');
    tag.innerHTML = html;
    const bodyTag = document.getElementsByTagName('body')[0];
    bodyTag.parentNode.insertBefore(tag, bodyTag);
    if (callback) callback();
};

export const clearScriptTag = (src) => {
    const tag = document.querySelector(`script[src="${src}"]`);
    if (tag) tag.remove();
};

export const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = url(src);
    });
};


export const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;
