<template>
    <Component
        :is="tag"
        ref="el"
        v-resize="updateDomValues"
        class="txt-component"
        :class="[
            variant,
            sizeOrDefaultSize,
            {
                flex,
                select,
                skeleton,
                edit: editable,
                touch: $browser && $browser.is.touch,
                clamp: isClamped,
                nowrap: !wrap,
                auto: isAutoClamp,
                'specific-size': isNumericSize,
                'visually-hidden': visuallyHidden && !editable,
                'line-break': lineBreak,
                'word-break': wordBreak,
                'align-left': align === 'left',
                'align-right': align === 'right',
                'align-center': align === 'center'
            }
        ]"
        @click="focus"
    >
        <span
            v-if="!editable && $slots.default"
            ref="content"
            class="txt-component-content"
            :style="{
                '-webkit-line-clamp': clampLineCount,
                fontSize: isNumericSize ? `${size}px` : null
            }"
        >
            <slot />
        </span>
        <span
            v-else
            ref="content"
            class="txt-component-content"
            :aria-label="placeholder"
            :contenteditable="editable"
            :style="{ '-webkit-line-clamp': clampLineCount }"
            @focus="handleFocus"
            @blur="handleBlur"
            @keydown.enter="handleEnter"
            @keyup.tab="selectAll"
            @keydown="handleKeydown"
            @keydown.delete="$emit('delete', $event)"
            @keydown.space="$emit('space', $event)"
            @keydown.tab="$emit('tab', $event)"
            @paste.prevent="insertPaste"
            @input="handleInput"
            v-text="textValue"
        />
    </Component>
</template>

<script>
import resize from '@/directives/resize';

export default {
    name: 'Txt',
    directives: { resize },
    props: {
        editable: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
        tag: { type: String, default: 'span' },
        value: { type: String, default: '' },
        placeholder: { type: String, default: '' },
        heading: { type: Boolean, default: false },
        monospaced: { type: Boolean, default: false },
        size: { type: [String, Number], default: null },
        align: { type: String, default: undefined },
        maxlength: { type: Number, default: undefined },
        // 'auto' for dynamic height (requires display: flex; flex-direction: column; on parent)
        // a number will clamp to multiple lines
        // true will default to 1 line
        clamp: { type: [Boolean, Number, String], default: false },
        flex: { type: Boolean, default: false },
        lineBreak: { type: Boolean, default: false },
        wordBreak: { type: Boolean, default: false },
        wrap: { type: Boolean, default: true },
        select: { type: Boolean, default: true }, // Allow disabling user-select on inner span
        skeleton: { type: Boolean, default: false },
        autofocus: { type: Boolean, default: false },
        visuallyHidden: { type: Boolean, default: false } // For non-editable instances of <Txt> where providing information to screen-readers is necessary
    },
    emits: ['delete', 'space', 'tab', 'focus', 'blur', 'paste', 'update:value', 'enter', 'update:dom'],
    slots: ['default'],
    data() {
        return {
            rootHeight: 0,
            lineHeight: 0,
            hasValueUpdated: false,
            cachedValue: this.value,
            isFocusing: false,
            textValue: null
        };
    },
    computed: {
        variant() {
            if (this.monospaced) return 'monospaced';
            if (this.heading) return 'heading';
            return 'body';
        },
        isNumericSize() {
            return typeof this.size === 'number';
        },
        sizeOrDefaultSize() {
            let size = 'm';
            if (this.heading) size = 'xs';
            if (this.isNumericSize) return;
            if (this.size) size = this.size;
            return size;
        },
        isClamped() {
            return Boolean(this.clamp);
        },
        isAutoClamp() {
            return this.clamp === 'auto';
        },
        clampLineCount() {
            if (typeof this.clamp === 'number') return this.clamp;
            // Setting clamp to fit within the available height
            if (this.isAutoClamp) return Math.max(1, Math.floor(this.rootHeight / this.lineHeight || 1));
            return 1;
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(to) {
                if (this.$slots.default) return;

                // Updating the component while it's focused will cause a clash
                if (this.editable && document.activeElement === this.$refs.content) return;

                if (to) to = String(to); // Coerce to string

                // Remove html entities on first load in case they are incorrectly purified by the server.
                const value = this.hasValueUpdated ? to : this.decodeHtmlEntities(to || '').trim();
                this.hasValueUpdated = true;
                this.textValue = value;
            }
        },
        isFocusing(to) {
            this.$emit(to ? 'focus' : 'blur');
        }
    },
    mounted() {
        this.updateDomValues();
        if (this.autofocus && this.editable) this.focus();
    },
    methods: {
        decodeHtmlEntities(text) {
            return text
                .replace(/&amp;/g, '&')
                .replace(/&gt;/g, '>')
                .replace(/&lt;/g, '<')
                .replace(/&nbsp;/g, ' ');
        },
        updateDomValues() {
            const rootHeight = this.$el.offsetHeight;
            if (!this.$el) return;
            const lineHeight = parseInt(window.getComputedStyle(this.$el).lineHeight, 10);

            this.$emit('update:dom', {
                contentHeight: this.$refs.content && this.$refs.content.scrollHeight,
                rootHeight,
                lineHeight
            });

            if (!this.isAutoClamp) return;

            this.rootHeight = rootHeight;
            this.lineHeight = lineHeight;
        },
        selectAll() {
            const range = document.createRange();
            range.selectNodeContents(this.$refs.content);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        },
        focus(event) {
            // Only focus when editable
            if (!this.editable) return;

            this.$refs.content.focus();

            // If <Txt> is within an element with a click event it messes up the caret
            // placement on click of the outer element even if stopPropagation is used
            // So here we have to manually set it to the end of the element.
            if (event && event.target === this.$refs.content) return;
            const range = document.createRange();
            range.selectNodeContents(this.$refs.content);
            range.collapse();
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        },
        blur() {
            this.$refs.content.blur();
        },
        handleFocus() {
            this.isFocusing = true;
        },
        handleBlur() {
            this.isFocusing = false;
            const target = this.$refs.content;
            if (!target) return;
            let value = this.lineBreak ? target.innerText : target.textContent;
            if (this.required && this.value === '') {
                value = this.cachedValue;
                this.$emit('update:value', value);
            }
            this.textValue = value;
        },
        handleKeydown(event) {
            if (event.key === 'Backspace' || event.key === 'Delete') return;
            const value = this.lineBreak ? event.target.innerText : event.target.textContent;
            if (this.maxlength && value.length > this.maxlength) event.preventDefault();
        },
        handleEnter(event) {
            this.$emit('enter', event);
            if (!this.lineBreak) {
                event.preventDefault();
                this.$refs.content.blur();
            }
        },
        handleInput(event) {
            const value = this.lineBreak ? event.target.innerText : event.target.textContent;
            this.cachedValue = this.value;
            this.$emit('update:value', value);
        },
        insertPaste(originalEvent) {
            const event = originalEvent.originalEvent || originalEvent;
            let text = event.clipboardData ? event.clipboardData.getData('text/plain') : window.clipboardData.getData('Text');
            const command = document.queryCommandSupported('insertText') ? 'insertText' : 'paste';
            const formattedText = this.lineBreak ? text.replace(/&nbsp;/gi, ' ').trim() : text.replace(/\r\n|\n|\r|&nbsp;/gi, ' ').trim();
            document.execCommand(command, false, formattedText);
            this.$emit('paste', formattedText);
        }
    }
};

</script>

<style lang="less">

.txt-component {
    --caret-colour: var(--primary);
    --edit-background-colour: var(--primary-fade-2);
    display: block;
    &.heading {
        font-family: var(--heading), sans-serif;
        font-weight: 700;
        &.xxl {
            font-size: 56px;
            line-height: 1.2;
            &.skeleton { min-height: 80px; }
        }
        &.xl { // H1+
            font-size: 48px;
            line-height: 64px;
            &.skeleton { min-height: 64px; }
        }
        &.l { // H1
            font-size: 32px;
            line-height: 48px;
            &.skeleton { min-height: 48px; }
        }
        &.m { // H2
            font-size: 24px;
            line-height: 32px;
            &.skeleton { min-height: 32px; }
        }
        &.s { // H3
            font-size: 20px;
            line-height: 28px;
            &.skeleton { min-height: 28px; }
        }
        &.xs { // H4
            font-size: 16px;
            line-height: 20px;
            &.skeleton { min-height: 20px; }
        }
        &.xxs { // H5
            font-size: 14px;
            line-height: 20px;
            &.skeleton { min-height: 20px; }
        }
        &.xxxs { // H6
            font-size: 12px;
            line-height: 16px;
            &.skeleton { min-height: 16px; }
        }
        &.xxxxs {
            font-size: 10px;
            line-height: 14px;
            &.skeleton { min-height: 14px; }
        }
        &.anchor { color: var(--anchor); }
        &.asphalt { color: var(--asphalt); }
        &.steel { color: var(--steel); }
        &.white { color: #FFF; }
        &.marker {
            text-transform: uppercase;
        }
    }
    &.body {
        font-family: var(--body), sans-serif;;
        font-weight: normal;
        &.xxl {
            font-size: 24px;
            line-height: 40px;
            &.skeleton { min-height: 40px; }
        }
        &.xl {
            font-size: 20px;
            line-height: 36px;
            &.skeleton { min-height: 36px; }
        }
        &.l {
            font-size: 18px;
            line-height: 32px;
            &.skeleton { min-height: 32px; }
        }
        &.m {
            font-size: 16px;
            line-height: 24px;
            &.skeleton { min-height: 24px; }
        }
        &.s {
            font-size: 14px;
            line-height: 20px;
            &.skeleton { min-height: 20px; }
        }
        &.xs { // sm
            font-size: 12px;
            line-height: 16px;
            &.skeleton { min-height: 16px; }
        }
        &.xxs {
            font-size: 10px;
            line-height: 16px;
            &.skeleton { min-height: 16px; }
        }
        &.marker {
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: 0.01em;
            &.skeleton { min-height: 12px; }
        }
        &.marker-s {
            text-transform: uppercase;
            font-size: 8px;
            font-weight: 500;
            line-height: 8px;
            letter-spacing: 0.01em;
            &.skeleton { min-height: 8px; }
        }
        &.gray, &.anchor { color: var(--anchor); }
        &.asphalt { color: var(--asphalt); }
        &.white { color: #FFF; }
        &.steel { color: var(--steel); }
    }
    p {
        font-size: inherit;
        line-height: inherit;
    }
    &.specific-size {
        line-height: 1.25;
    }
    &.monospaced {
        font-family: monospace;
    }
    &.edit {
        cursor: text;
        overflow: visible;
        .txt-component-content { user-select: auto; }
        &:hover:not(:focus-within):not(.touch) {
            .txt-component-content { background-color: var(--edit-background-colour); }
        }
        .txt-component-content:focus-visible {
            outline: none;
        }
    }
    &.visually-hidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    &.nowrap {
        white-space: nowrap;
    }
    &.word-break {
        .txt-component-content { word-break: break-word; }
    }
    &.line-break {
        white-space: pre-line;
        .txt-component-content { white-space: pre-line; }
    }
    &.align-left { text-align: left; }
    &.align-center { text-align: center; }
    &.align-right { text-align: right; }
    &.clamp {
        white-space: normal;
        &.auto {
            overflow: hidden;
            flex: 1;
        }
        > .txt-component-content {
            display: -webkit-box;
            overflow: hidden;
            /*! autoprefixer: ignore next */
            -webkit-box-orient: vertical;
        }
    }
    &.select {
        .txt-component-content { user-select: auto; }
    }
    &.flex {
        > .txt-component-content {
            display: flex;
            align-items: center;
        }
    }
    &-content {
        user-select: none;
        transition: background-color 0.15s var(--curve);
        // Override focus-within polyfill
        &:focus { box-shadow: none !important; }
        &:empty {
            // Won't be visible if inline
            display: inline-block;
            &::before {
                color: var(--placeholder-colour, var(--anchor));
                content: attr(aria-label);
            }
        }
        // These selectors won't work unless they're separated
        &::selection {
            color: var(--selection-colour);
            background-color: var(--selection-background-colour);
        }
        &::-moz-selection {
            color: var(--selection-colour);
            background-color: var(--selection-background-colour);
        }
    }
}
</style>
