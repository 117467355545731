export const $track = {
    user: null,
    client: null,
    clients: [],
    globalProperties: {},
    baseProperties: {},
    isEnterprise: false,

    transformId(id) {
        if (!id) return undefined;
        if (Array.isArray(id)) {
            return id.map(item => {
                if (String(item).endsWith('e')) return String(item);
                return `${item}${this.isEnterprise ? 'e' : ''}`;
            });
        }
        if (String(id).endsWith('e')) return String(id);
        return `${id}${this.isEnterprise ? 'e' : ''}`;
    },

    transformIdsInProperties(properties = {}) {
        const idFields = [
            'groupId',
            'workspace_id',
            'user_id',
            'id',
            'course_id',
            'organisation_id',
            'folder_id',
            'course_id_original',
            'tracking_id',
            'workspace_creator_id',
            'created_workspace_id'
        ];

        const transformed = { ...properties };
        idFields.forEach(field => {
            if (transformed[field] !== undefined) {
                transformed[field] = this.transformId(transformed[field]);
            }
        });
        return transformed;
    },

    set(user, client) {
        if (user) this.user = user;
        if (client) this.client = client;
    },
    reset() {
        if (!window.analytics) return;
        window.analytics.reset();
    },
    page(type = 'app', name, meta = {}) {
        if (!window.analytics) return;
        if (this.user && this.user.impersonating) return;
        window.analytics.page(type, name, this.properties(meta));
    },
    event(event, meta = {}) {
        if (!window.analytics) return;
        if (this.user && this.user.impersonating) return;
        window.analytics.track(event, this.properties(meta));
    },
    anon(meta = {}) {
        if (!window.analytics) return;
        window.analytics.identify(this.transformIdsInProperties(meta));
    },
    identify(traits = {}) {
        if (!window.analytics) return;
        if (this.user && this.user.impersonating) return;
        const properties = this.userTraits();
        if (window.analytics) {
            window.analytics.identify(this.transformId(this.user.id), { ...properties, ...traits });
        }
        this.group();
    },
    group(traits = {}) {
        if (window.analytics) {
            const properties = this.groupTraits();
            if (properties) window.analytics.group(properties.workspace_id, { ...properties, ...traits });
        }
    },
    properties(meta = {}) {
        const email = this.user && this.user.email;
        if (email) meta.email = email;
        const properties = {
            ...this.globalProperties,
            ...this.baseProperties,
            ...this.superProperties(),
            ...this.transformIdsInProperties(meta),
            groupId: this.client && String(this.client.id)
        };
        return properties;
    },
    superProperties() {
        const properties = {};
        const userTraits = this.userTraits();
        const groupTraits = this.groupTraits();
        if (userTraits) properties['user_traits'] = userTraits;
        if (groupTraits) properties['group_traits'] = groupTraits;
        return properties;
    },
    userTraits() {
        if (!this.user) return;
        const traits = {
            'name': this.user.name,
            'first_name': this.user.firstname,
            'last_name': this.user.lastname,
            'created_at': this.user.created,
            'email': this.user.email,
            'is_transform_user': !!(this.client),
            'is_transform_paid': this.client && this.client.upgraded,
            'is_lms_user': this.user.lmsUser,
            'user_role': this.client ? (this.client.ownerId === this.user.id ? 'owner' : 'team member') : null,
            'workspaces_belongs_to': this.clients && this.clients.map(client => this.transformId(client.id)),
            'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        return traits;
    },
    groupTraits() {
        if (!this.client) return;
        return {
            'workspace_id': this.transformId(this.client.id),
            'created_at': this.client.created,
            'plan_name': this.client.plan,
            'user_limit': this.client.userLimit,
            'is_transform_workspace': !this.client.lms,
            'is_lms_workspace': this.client.lms,
            'is_headless': this.client.actions && this.client.actions.headless && !this.client.actions.headless_test,
            'is_headless_course_creation': this.client.actions && this.client.actions.headless_authoring,
            'is_payment_links': this.client.actions && this.client.actions.payment_links && !this.client.actions.payment_links_test,
            'headless_test': this.client.actions && this.client.actions.headless_test,
            'payment_links_test': this.client.actions && this.client.actions.payment_links_test && this.client.actions.payment_links_stripe_onboarded,
        };
    }
};

export default function Analytics(app) {
    const vueApp = app.vueApp || app;
    vueApp.config.globalProperties.$track = $track;
}
