
<template>
    <span
        v-if="name"
        :class="[{ 'm-right': right, 'm-left': left }, type ? `${type}-icon` : null]"
        :style="{
            minWidth: `${size}px`,
            minHeight: `${size}px`
        }"
        class="icon"
    >
        <Component
            :is="component"
            :style="style"
            v-bind="iconBind"
            :aria-label="label"
            :role="label ? 'img' : 'presentation'"
        />
    </span>
</template>

<script>
import { defineAsyncComponent } from 'vue';

const removeExtension = /(^.\/)|(\.svg$)/g;
const importIcons = (imports) => {
    return Object.keys(imports)
        .reduce((components, path) => {
            const component = defineAsyncComponent(imports[path]);
            let [folder, name] = path.replace('/assets/icons/', '').replace(removeExtension, '').split('/');
            if (!components[folder]) components[folder] = {};
            components[folder][name] = component;

            if (folder === 'question') {
                if (!components.checkpoint) components.checkpoint = {};
                components.checkpoint[name] = component;
            }
            return components;
        }, {});
};
export const ICON_MAP = importIcons(import.meta.glob('@/assets/icons/**/*.svg'));

export default {
    name: 'Icon',
    props: {
        name: { type: String, default: null, options: 'icon' },
        type: { type: String, default: null },
        size: { type: String, default: '24' },
        label: { type: String, default: null },

        right: { type: Boolean, default: false },
        left: { type: Boolean, default: false },
    },
    computed: {
        iconMap() {
            return ICON_MAP;
        },
        iconBind() {
            if (this.type === 'checkpoint' || this.type === 'screen' || this.type === 'question') return {};
            if (this.type) return { viewBox: this.type === 'module' ? '0 0 24 24' : '0 0 112 112' };
            return {};
        },
        iconMapTypes() {
            return Object.keys(ICON_MAP);
        },
        component() {
            const icon = this.name.replace(removeExtension, '');
            if (!this.type && ICON_MAP.system) return ICON_MAP.system[icon];
            const map = this.iconMapTypes.includes(this.type) ? ICON_MAP[this.type] : ICON_MAP.tiles;
            if (!map) return;
            return map[icon];
        },
        style() {
            return { width: `${this.size}px`, height: `${this.size}px` };
        }
    }
};
</script>

<style lang="less" scoped>

span.icon {
    display: inline-flex;
    fill: currentColor;
    &.m-left  { margin-left: 8px; }
    &.m-right { margin-right: 8px; }
    &.white { color: #FFF; }
    &.gray, &.light, &.anchor {
        color: var(--anchor);
        fill: var(--anchor);
    }
    &.smoke { color: var(--smoke); }
    &.steel { color: var(--steel); }
    &.mercury { color: var(--mercury) }
    svg {
        pointer-events: none;
    }
}
</style>

<style lang="less">

span.icon {
    &.module-icon {
        .faded { fill: var(--primary-tint-2); }
        .primary { fill: var(--primary); }
        .contrast { fill: var(--primary-contrast); }
        .faded-stroke { stroke: var(--primary-tint-2); }
        .primary-stroke { stroke: var(--primary); }
        .faded-stroke, .primary-stroke { stroke-width: 3.25px; }
    }
    &.question-icon {
        svg { border-radius: 25%; }
        .bg { fill: #ECF0FF; }
        .shape { fill: var(--primary); }
        .stroke {
            stroke: var(--primary);
            fill: transparent;
        }
    }
    &.tile-icon {
        svg { border-radius: 25%; }
    }
    &.screen-icon {
        svg { border-radius: 25%; }
        &:not(.active) {
            .primary { fill: var(--anchor); }
            .secondary { fill: var(--steel); }
            .bg { fill: var(--cloud); }
        }
    }
    &.checkpoint-icon,
    &.pictureExample-icon,
    &.txtImage-icon,
    &.video-icon,
    &.intro-icon,
    &.videoSingle-icon,
    &.videoMultiple-icon,
    &.finish-icon,
    &.document-icon,
    &.embed-icon,
    &.documentUpload-icon {
        svg { border-radius: 25%; }
        .bg { fill: var(--navy); }
        .shape { fill: #FFF; }
        .stroke {
            stroke: #FFF;
            fill: transparent;
        }
    }
    &.iaWheel-icon,
    &.iaMudMap-icon,
    &.iaSpringyCircle-icon,
    &.iaPhotoClick-icon,
    &.iaHotspot-icon,
    &.iaSteps-icon,
    &.iaPlusPopup-icon,
    &.iaIconPop-icon,
    &.iaAcronym-icon,
    &.iaStepsFlow-icon,
    &.iaIconHover-icon,
    &.iaDropTab-icon,
    &.iaSideSlide-icon,
    &.iaMenusite-icon,
    &.iaTimeline2-icon,
    &.iaChecklist-icon,
    &.tapToReveal-icon,
    &.process-icon,
    &.caseStudy-icon {
        svg { border-radius: 25%; }
        .bg { fill: #00BBF9; }
        .shape { fill: #FFF; }
    }
    &.iaDragndrop-icon,
    &.iaCorrectCategory-icon,
    &.iaFindMistakes-icon,
    &.iaCorrectImage-icon,
    &.iaMatchsequence-icon,
    &.iaFormChallenge-icon,
    &.iaDialChallenge-icon,
    &.branchingScenario-icon,
    &.iaLabellingChallenge-icon {
        svg { border-radius: 25%; }
        .bg { fill: #B47FF2; }
        .shape { fill: #FFF; }
        .stroke {
            stroke: #FFF;
            fill: transparent;
        }
    }
}
</style>
