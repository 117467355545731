import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

import { locales } from '@/helpers/i8n';
const userLocale = (window && window.navigator && window.navigator.webdriver ? 'en' : (window && window.navigator && window.navigator.userLanguage || window && window.navigator && window.navigator.language)) || 'en';
const localeLoader = locales[userLocale] || locales.en;
localeLoader().then(() => dayjs.locale(userLocale));

export const $date = dayjs;
export const $time = dayjs.duration;
export const formatTime = time => Math.floor($time(time).as('hours')) + $time(time).format(':mm:ss');
export default function Datetime(app) {
    const vueApp = app.vueApp || app;
    vueApp.config.globalProperties.$date = dayjs;
    vueApp.config.globalProperties.$time = dayjs.duration;
    vueApp.config.globalProperties.$time.format = formatTime;
}

