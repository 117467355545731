/* eslint-disable no-undef */

export default defineNuxtRouteMiddleware((to) => {
    if (to.path.includes('/craft')) {
        const { path, query, hash } = to;
        const nextPath = path.replace('/craft', '') || '/';
        const nextRoute = { path: nextPath, query, hash };
        return navigateTo(nextRoute, { redirectCode: 301 });
    }
});
