<template>
    <Component
        :is="$attrs.href ? 'a' : 'button'"
        class="clickable"
        :class="{ touch: $browser && $browser.is.touch }"
        :tabindex="tabindex"
        @mouseenter="isHovering = true; $emit('mouseenter', $event);"
        @mouseleave="isHovering = false; $emit('mouseleave', $event);"
    >
        <slot v-bind="{ hovering: isHovering }" />
    </Component>
</template>

<script>
export default {
    name: 'Clickable',
    inject: {
        tabindex: {
            default: null
        }
    },
    emits: ['mouseenter', 'mouseleave', 'hover'],
    data() {
        return {
            isHovering: false
        };
    },
    watch: {
        isHovering(to) {
            this.$emit('hover', to);
        }
    }
};
</script>

<style lang="less">
.clickable {
    cursor: pointer;
    display: inline-flex;
    background: none;
    border: none;
    font-family: inherit;
    text-align: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    align-items: unset;
    border-radius: inherit;
    appearance: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    &:hover { color: inherit; }
    &:focus { outline: none; }
    &:focus-visible {
        outline: 2px solid var(--focus-colour, var(--coal));
        outline-offset: 2px;
    }
    &[disabled], &.disabled { cursor: auto; }
}
</style>
