export default function Password(app) {
    const vueApp = app.vueApp || app;
    vueApp.config.globalProperties.$password = {
        strength(password) {
            let score = 0;
            if (!password) return score;

            // Add points for common password variations
            const variations = [
                /\d/.test(password),            // Digits
                /[a-z]/.test(password),         // Lowercase
                /[A-Z]/.test(password),         // Uppercase
                /[^a-zA-Z0-9]/.test(password),  // Special characters
            ];
            score += variations.filter(Boolean).length;

            // Add points for length of password
            if (password.length >= 8) score += 1;

            // score can be max 5
            // do some maths to get a score between 1-4
            return Math.round((score - 1) * 4 / 5) + 1;
        }
    };
}
