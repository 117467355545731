import { $date } from '@/services/datetime';
import { isLegacy } from '@/helpers/billing';

export const $access = {
    windowData: window,
    workspaceActions: [ 'share', 'create', 'teach', 'manage_enrolment', 'invite', 'admin' ],
    categoryActions: [ 'share', 'create', 'teach', 'manage_enrolment', 'admin' ],
    courseActions: [ 'create', 'teach', 'manage_enrolment' ],
    resourceActions: [ 'create' ],
    has(action) {
        const actions = this.windowData.actions || [];
        return actions.includes(action);
    },
    setUserStatus(user) {
        if (user.active === undefined && user.removed === undefined) return user;
        let status = 'pending';
        if (user.invitationSent) status = 'invited';
        if (user.invitation && user.invitation.sent) status = 'invited';
        if (user.active) status = 'active';
        if (user.removed) status = 'deactivated';
        user.status = status;
        return user;
    },
    getRole(actions, categories = null) {
        let result = 'custom';

        // If there are no actions passed in, assume custom
        if (!actions || actions.length === 0) return result;

        const $actions = [ ...new Set(actions) ];
        // If actions includes admin, assume admin
        if ($actions.includes('admin')) return 'admin';

        // Remove some actions for comparison
        if ($actions.includes('learn')) $actions.splice($actions.indexOf('learn'), 1);
        if ($actions.includes('member')) $actions.splice($actions.indexOf('member'), 1);
        if ($actions.includes('train')) $actions.splice($actions.indexOf('train'), 1);
        if ($actions.includes('edit')) $actions.splice($actions.indexOf('edit'), 1);

        // If no actions after removing member and train, return custom
        if ($actions.length === 0) return result;

        // If there is only one action, set that action as their role for now
        if ($actions.length === 1) result = $actions[0];

        Object.keys(this.roles).forEach(name => {
            // Copy array
            const role = this.roles[name].slice();

            // Remove some actions for comparison
            if (role.includes('member')) role.splice(role.indexOf('member'), 1);
            if (role.includes('train')) role.splice(role.indexOf('train'), 1);

            if ($actions.length === role.length) {
                // If lengths are the same, check for an exact match
                if ($actions.every(action => role.includes(action))) return result = name;
            } else if ($actions.length > role.length) {
                // If user's actions length is greater, check for a partial match
                if (role.every(action => $actions.includes(action))) return result = `${name}_plus`;
            }
        });

        if (categories) {
            const hasCategoryPermission = categories.some(category => {
                if (!category.actions || !category.actions.length) return false;
                // If every category action is present in workspace role, don't make it custom
                return !category.actions.every(action => $actions.includes(action));
            });

            if (hasCategoryPermission) return 'custom';
        }

        if (result === 'custom') {
            // If nothing has matched, check for some edge case combinations
            const edgeCases = {
                'teach_plus': [ 'invite', 'teach' ],
                'enrol_plus': [ 'manage_enrolment', 'invite' ]
            };
            Object.keys(edgeCases).forEach(role => {
                if ($actions.sort().every((action, index)=> edgeCases[role][index] === action)) return result = role;
            });
        }

        return result;
    },
    install(app, windowData = window) {
        this.windowData = windowData;
        this.actions = windowData.actions || [];
        this.caps = windowData.client && windowData.client.caps || {};

        const creator = [ 'create', 'share', 'member', 'train' ];
        const facilitator = [ 'teach', 'manage_enrolment', 'member', 'train' ];
        const manager = [ 'create', 'share', 'teach', 'manage_enrolment', 'invite', 'member', 'train' ];
        const admin = [ 'create', 'share', 'teach', 'manage_enrolment', 'invite', 'admin', 'member', 'train' ];

        this.roles = this.has('role_creator') && this.has('role_facilitator')
            ? { creator, facilitator, manager, admin }
            : { manager, admin };

        this.isFutura = windowData.isFutura;
        this.isCSAdmin = windowData.isCSAdmin;
        this.isSuperAdmin = windowData.isSuperAdmin;
        this.isHeadless = windowData.isHeadless;

        const now = $date();
        const client = windowData.client || {};
        const payment = client.payment || {};
        const plan = payment.plan || {};
        const trialData = client.lpx || {};
        this.isTrial = payment.trial;
        this.isLimited = payment.limited && $date(payment.limited).isBefore(now) || false;
        this.isCancelled = payment.limited && $date(payment.limited).isAfter(now) || false;
        this.isLaunchpad = this.isTrial && !trialData.started;
        this.isEternal = plan.duration === 'P999Y';
        this.isPartner = plan.partner;
        this.isLegacy = isLegacy(plan.name);
        this.trialDays = windowData.trialDays;
        this.isTrialExpired = this.isTrial && (this.isLimited || this.trialDays === 0);
        this.isAuthor = !windowData.actions;

        this.canGlobalAdmin = this.caps.admin;
        this.canGlobalFinance = this.caps.finance;
        this.canGlobalInvite = this.caps.invite;
        this.canGlobalCreate = this.caps.create;
        this.canGlobalEnrol = this.caps.enrol;
        this.canGlobalTeach = this.caps.teach;

        this.canIntercom = ((client.options && client.options.intercom_student_enable) || this.has('complain') || (this.isTrial && this.canGlobalAdmin)) && !windowData.isImpersonating;
        this.canAI = this.has('ai_assistant') || this.isLaunchpad || this.isCSAdmin || this.isSuperAdmin;
        this.canStudy = windowData.paths && windowData.paths.study;
        this.canEnrol = this.canGlobalEnrol || windowData.canEnrol;
        this.canTeach = this.canGlobalTeach || windowData.canTeach;
        this.canGroupTeach = this.canGlobalTeach || windowData.canGroupTeach;

        this.hasAdvancedIntegrations = this.has('rest') || this.has('configure_webhooks') || this.has('student_intercom') || this.has('saml_auth') || this.has('oauth2_auth');
    }
};

export default function Access(app) {
    $access.install(app);

    const vueApp = app.vueApp || app;
    vueApp.config.globalProperties.$access = $access;
}
