import revive_payload_client_aomkfg_8x0lLzFGhMUWCc5bPNMxB_wz8FTBlAxAEiVw from "/home/runner/work/coassemble-author/coassemble-author/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.3.1_ioredis@5.6.0_less@4.2._b7b5d6949da24b1563de47d2fb6de93a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fA1e8TG6CEfumnsveUCX13s094sl7AXzAY4oMxUOC94 from "/home/runner/work/coassemble-author/coassemble-author/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.3.1_ioredis@5.6.0_less@4.2._b7b5d6949da24b1563de47d2fb6de93a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RdFB2naG12snlXOYLxCIcr_E_ZFlOTpajsvi1bfzpr4 from "/home/runner/work/coassemble-author/coassemble-author/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.3.1_ioredis@5.6.0_less@4.2._b7b5d6949da24b1563de47d2fb6de93a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_ZSk_CVWlKtatcpThQ0PuEIsdVdmzIsxDbr2C5uc3JUU from "/home/runner/work/coassemble-author/coassemble-author/frontend/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.2.4_@types+node@22.13.5_jiti@2.4.2_less@4_6812f242b9d41e48032b960dfb8c77b3/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_mE2WQ3gEJEu7VMRP6gMZityTZhSGKWp9WlUmKnhixko from "/home/runner/work/coassemble-author/coassemble-author/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.3.1_ioredis@5.6.0_less@4.2._b7b5d6949da24b1563de47d2fb6de93a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_QjwDYCdLi6fVgvnYR81s1jJXc9o3NcUi2Qk0QfOYtBs from "/home/runner/work/coassemble-author/coassemble-author/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.3.1_ioredis@5.6.0_less@4.2._b7b5d6949da24b1563de47d2fb6de93a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/runner/work/coassemble-author/coassemble-author/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_8GALdxhvpsrkHxi3rScwnmCDIs64Au2ynL0PoifUVNI from "/home/runner/work/coassemble-author/coassemble-author/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.3.1_ioredis@5.6.0_less@4.2._b7b5d6949da24b1563de47d2fb6de93a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import access_lHYvUX_0TLYwcd0jzQN1PDOs6dRs8Peh4MtjNa1A4rI from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/services/access.js";
import analytics_B3f0vj6H8BCx9vw_KMmI8xCGmMGLVbexk2kuYalLiv0 from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/plugins/analytics.js";
import bus_IQDihNMhqRGcpWLxU4kLP58xh0_PCxWzSMgB1Iu5N4s from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/services/bus.js";
import confetti_9zELnkeXBsmlSPzUjwRQ3OjIuOsEFbYxHw8_x7OdUiM from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/services/confetti.js";
import datetime_aN_MgUGAnNbyJayTUqJm3A1F18WagFb7aBxU66eviVA from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/services/datetime.js";
import http_aNpY7r_0YpDVaCAusJzpt2SqxZ1SaRQ1244NfUqmOP0 from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/plugins/http.js";
import password_TEW5mPYZmaP8XgNbmUYDNsNd0wbdPToNT4iYNsYfvZk from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/services/password.js";
import scroll_plhgMh4tP1qo75L7gm_3di3_xl6PPgEO3r1T0Kmrrxg from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/services/scroll.js";
import timeout_B3BkkCKSIz1Oi2rObLlr_EcgBh7axGLfZaXHHCh5qrA from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/services/timeout.js";
import translator_8H0nuC7rmo0Jd_cy16xAePCkCW_mPPVqaVjQQ9LhAHE from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/plugins/translator.js";
import window_7Nfwje5ft3v3O_nMlBFxZk50AuZYdo_KZGzBRJcAq2I from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/services/window.js";
export default [
  revive_payload_client_aomkfg_8x0lLzFGhMUWCc5bPNMxB_wz8FTBlAxAEiVw,
  unhead_fA1e8TG6CEfumnsveUCX13s094sl7AXzAY4oMxUOC94,
  router_RdFB2naG12snlXOYLxCIcr_E_ZFlOTpajsvi1bfzpr4,
  _0_siteConfig_ZSk_CVWlKtatcpThQ0PuEIsdVdmzIsxDbr2C5uc3JUU,
  navigation_repaint_client_mE2WQ3gEJEu7VMRP6gMZityTZhSGKWp9WlUmKnhixko,
  chunk_reload_client_QjwDYCdLi6fVgvnYR81s1jJXc9o3NcUi2Qk0QfOYtBs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_8GALdxhvpsrkHxi3rScwnmCDIs64Au2ynL0PoifUVNI,
  access_lHYvUX_0TLYwcd0jzQN1PDOs6dRs8Peh4MtjNa1A4rI,
  analytics_B3f0vj6H8BCx9vw_KMmI8xCGmMGLVbexk2kuYalLiv0,
  bus_IQDihNMhqRGcpWLxU4kLP58xh0_PCxWzSMgB1Iu5N4s,
  confetti_9zELnkeXBsmlSPzUjwRQ3OjIuOsEFbYxHw8_x7OdUiM,
  datetime_aN_MgUGAnNbyJayTUqJm3A1F18WagFb7aBxU66eviVA,
  http_aNpY7r_0YpDVaCAusJzpt2SqxZ1SaRQ1244NfUqmOP0,
  password_TEW5mPYZmaP8XgNbmUYDNsNd0wbdPToNT4iYNsYfvZk,
  scroll_plhgMh4tP1qo75L7gm_3di3_xl6PPgEO3r1T0Kmrrxg,
  timeout_B3BkkCKSIz1Oi2rObLlr_EcgBh7axGLfZaXHHCh5qrA,
  translator_8H0nuC7rmo0Jd_cy16xAePCkCW_mPPVqaVjQQ9LhAHE,
  window_7Nfwje5ft3v3O_nMlBFxZk50AuZYdo_KZGzBRJcAq2I
]