export default function Scroll(app) {
    const scrollingElement = document.querySelector('.capsule-wrapper') || document.scrollingElement || document.querySelector('html');

    const vueApp = app.vueApp || app;
    vueApp.config.globalProperties.$scroll = {
        scrollingElement,
        enable(element) {
            const el = element || scrollingElement;
            el.style.overflow = 'auto';
            if (!element) el.style.paddingRight = 0;
        },
        disable(element) {
            const el = element || scrollingElement;
            el.style.overflow = 'hidden';

            if (!element) {
                const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
                el.style.paddingRight = `${scrollBarWidth}px`;
            }
        },
        to(element, position, behavior = 'smooth') {
            const el = element || scrollingElement;
            el.scrollTo({ top: position, behavior });
        },
        toTop(element) {
            const el = element || scrollingElement;
            el.scrollTo({ top: 0, behavior: 'smooth' });
        },
        toBottom(element) {
            const el = element || scrollingElement;
            el.scrollTo({ top: el.scrollHeight || el.clientHeight, behavior: 'smooth' });
        },
        toLeft(element) {
            const el = element || scrollingElement;
            el.scrollTo({ left: 0, behavior: 'smooth' });
        },
        toRight(element) {
            const el = element || scrollingElement;
            el.scrollTo({ left: el.scrollWidth || el.clientWidth, behavior: 'smooth' });
        },
        isAtTop(element) {
            const el = element || scrollingElement;
            return el.scrollTop > 0;
        },
        isAtBottom(element, buffer = 0) {
            const el = element || scrollingElement;
            return (el.scrollHeight - el.scrollTop - buffer) <= el.clientHeight;
        },
        isScrollable(element) {
            const el = element || scrollingElement;
            return el.scrollHeight > el.clientHeight;
        }
    };
}
