<template>
    <svg id="plyr-icons" style="display: none;">
        <symbol id="plyr-airplay" viewBox="0 0 24 24">
            <path d="M5 2.25c-1.57 0-2.75 1.36-2.75 2.92v8.66c0 1.56 1.18 2.92 2.75 2.92h2a.75.75 0 0 0 0-1.5H5c-.63 0-1.25-.58-1.25-1.42V5.17c0-.84.62-1.42 1.25-1.42h14c.64 0 1.25.58 1.25 1.42v8.66c0 .84-.61 1.42-1.25 1.42h-2a.75.75 0 0 0 0 1.5h2c1.57 0 2.75-1.36 2.75-2.92V5.17c0-1.56-1.18-2.92-2.75-2.92H5Z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M12 14.25c-.5 0-.99.22-1.32.6l-3.49 3.97a1.77 1.77 0 0 0 1.32 2.93h6.98a1.77 1.77 0 0 0 1.32-2.93l-3.49-3.97c-.33-.38-.81-.6-1.32-.6Zm-.2 1.59a.26.26 0 0 1 .4 0l3.48 3.97a.27.27 0 0 1-.2.44H8.52a.27.27 0 0 1-.2-.44l3.5-3.97Z" />
        </symbol>
        <symbol id="plyr-captions-off" viewBox="0 0 18 18">
            <path
                d="M1 1c-.6 0-1 .4-1 1v11c0 .6.4 1 1 1h4.6l2.7 2.7c.2.2.4.3.7.3.3 0 .5-.1.7-.3l2.7-2.7H17c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1H1zm4.52 10.15c1.99 0 3.01-1.32 3.28-2.41l-1.29-.39c-.19.66-.78 1.45-1.99 1.45-1.14 0-2.2-.83-2.2-2.34 0-1.61 1.12-2.37 2.18-2.37 1.23 0 1.78.75 1.95 1.43l1.3-.41C8.47 4.96 7.46 3.76 5.5 3.76c-1.9 0-3.61 1.44-3.61 3.7 0 2.26 1.65 3.69 3.63 3.69zm7.57 0c1.99 0 3.01-1.32 3.28-2.41l-1.29-.39c-.19.66-.78 1.45-1.99 1.45-1.14 0-2.2-.83-2.2-2.34 0-1.61 1.12-2.37 2.18-2.37 1.23 0 1.78.75 1.95 1.43l1.3-.41c-.28-1.15-1.29-2.35-3.25-2.35-1.9 0-3.61 1.44-3.61 3.7 0 2.26 1.65 3.69 3.63 3.69z"
                fill-rule="evenodd" fill-opacity=".5"
            />
        </symbol>
        <symbol id="plyr-captions-on" viewBox="0 0 18 18">
            <path
                d="M1 1c-.6 0-1 .4-1 1v11c0 .6.4 1 1 1h4.6l2.7 2.7c.2.2.4.3.7.3.3 0 .5-.1.7-.3l2.7-2.7H17c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1H1zm4.52 10.15c1.99 0 3.01-1.32 3.28-2.41l-1.29-.39c-.19.66-.78 1.45-1.99 1.45-1.14 0-2.2-.83-2.2-2.34 0-1.61 1.12-2.37 2.18-2.37 1.23 0 1.78.75 1.95 1.43l1.3-.41C8.47 4.96 7.46 3.76 5.5 3.76c-1.9 0-3.61 1.44-3.61 3.7 0 2.26 1.65 3.69 3.63 3.69zm7.57 0c1.99 0 3.01-1.32 3.28-2.41l-1.29-.39c-.19.66-.78 1.45-1.99 1.45-1.14 0-2.2-.83-2.2-2.34 0-1.61 1.12-2.37 2.18-2.37 1.23 0 1.78.75 1.95 1.43l1.3-.41c-.28-1.15-1.29-2.35-3.25-2.35-1.9 0-3.61 1.44-3.61 3.7 0 2.26 1.65 3.69 3.63 3.69z"
                fill-rule="evenodd"
            />
        </symbol>
        <symbol id="plyr-download" viewBox="0 0 18 18">
            <path d="M9 13c.3 0 .5-.1.7-.3L15.4 7 14 5.6l-4 4V1H8v8.6l-4-4L2.6 7l5.7 5.7c.2.2.4.3.7.3zm-7 2h14v2H2z" />
        </symbol>
        <symbol id="plyr-enter-fullscreen" viewBox="0 0 24 24">
            <path d="M3 9.67a.75.75 0 0 1-.75-.75V4c0-.97.78-1.75 1.75-1.75h4.98a.75.75 0 0 1 0 1.5H4a.25.25 0 0 0-.25.25v4.92c0 .42-.34.75-.75.75ZM15.08 2.25a.75.75 0 0 0 0 1.5H20c.14 0 .25.11.25.25v4.98a.75.75 0 0 0 1.5 0V4c0-.97-.78-1.75-1.75-1.75h-4.92ZM8.92 21.75a.75.75 0 0 0 0-1.5H4a.25.25 0 0 1-.25-.25v-4.98a.75.75 0 0 0-1.5 0V20c0 .97.78 1.75 1.75 1.75h4.92ZM21.74 15.08a.75.75 0 0 0-1.5 0V20c0 .14-.1.25-.25.25h-4.98a.75.75 0 1 0 0 1.5H20c.97 0 1.75-.78 1.75-1.75v-4.92Z" />
        </symbol>
        <symbol id="plyr-exit-fullscreen" viewBox="0 0 24 24">
            <path d="M21 8.24h-5a.25.25 0 0 1-.24-.25V3a.75.75 0 1 0-1.5 0v5c0 .96.78 1.74 1.74 1.74h5a.75.75 0 1 0 0-1.5ZM3 15.76H8c.14 0 .25.11.25.25v4.96a.75.75 0 0 0 1.5 0V16c0-.97-.78-1.75-1.75-1.75H2.99a.75.75 0 0 0 0 1.5ZM8.27 8V3a.75.75 0 1 1 1.5 0v5c0 .97-.79 1.75-1.75 1.75H3a.75.75 0 1 1 0-1.5h5.02c.14 0 .25-.11.25-.25ZM14.26 21a.75.75 0 0 0 1.5 0v-5c0-.13.11-.24.25-.24h5a.75.75 0 0 0 0-1.5h-5c-.97 0-1.75.78-1.75 1.75V21Z" />
        </symbol>
        <symbol id="plyr-fast-forward" viewBox="0 0 18 18">
            <path d="M7.875 7.171L0 1v16l7.875-6.171V17L18 9 7.875 1z" />
        </symbol>
        <symbol id="plyr-logo-vimeo" viewBox="0 0 18 18">
            <path
                d="M17 5.3c-.1 1.6-1.2 3.7-3.3 6.4-2.2 2.8-4 4.2-5.5 4.2-.9 0-1.7-.9-2.4-2.6C5 10.9 4.4 6 3 6c-.1 0-.5.3-1.2.8l-.8-1c.8-.7 3.5-3.4 4.7-3.5 1.2-.1 2 .7 2.3 2.5.3 2 .8 6.1 1.8 6.1.9 0 2.5-3.4 2.6-4 .1-.9-.3-1.9-2.3-1.1.8-2.6 2.3-3.8 4.5-3.8 1.7.1 2.5 1.2 2.4 3.3z"
            />
        </symbol>
        <symbol id="plyr-logo-youtube" viewBox="0 0 18 18">
            <path
                d="M16.8 5.8c-.2-1.3-.8-2.2-2.2-2.4C12.4 3 9 3 9 3s-3.4 0-5.6.4C2 3.6 1.3 4.5 1.2 5.8 1 7.1 1 9 1 9s0 1.9.2 3.2c.2 1.3.8 2.2 2.2 2.4C5.6 15 9 15 9 15s3.4 0 5.6-.4c1.4-.3 2-1.1 2.2-2.4.2-1.3.2-3.2.2-3.2s0-1.9-.2-3.2zM7 12V6l5 3-5 3z"
            />
        </symbol>
        <symbol id="plyr-muted" viewBox="0 0 24 24">
            <path d="M10.34 5.83 6.36 9.2l3.98-3.37Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="m5.6 7.87 3.77-3.19a1.75 1.75 0 0 1 2.88 1.34v11.96c0 1.5-1.74 2.3-2.88 1.34l-3.77-3.2H3.5c-.97 0-1.75-.78-1.75-1.75V9.62c0-.96.78-1.75 1.75-1.75h2.1Zm4.74-2.04a.25.25 0 0 1 .41.19v11.96c0 .22-.25.33-.41.2L6.36 14.8a.75.75 0 0 0-.52-.18H3.5a.25.25 0 0 1-.25-.25V9.62c0-.13.11-.25.25-.25h2.38c.15 0 .3-.04.42-.13" />
            <path d="M16.74 9.29a.75.75 0 0 0-1.06 1.06l1.59 1.59-1.6 1.6a.75.75 0 0 0 1.07 1.05L18.33 13l1.6 1.6a.75.75 0 1 0 1.05-1.07l-1.59-1.59 1.6-1.6a.75.75 0 0 0-1.07-1.05l-1.59 1.59-1.6-1.6Z" />
        </symbol>
        <symbol id="plyr-pause" viewBox="0 0 20 20">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.34 2a2.63 2.63 0 0 0-2.62 2.63v10.5a2.63 2.63 0 0 0 5.25 0V4.63A2.63 2.63 0 0 0 5.34 2ZM14.68 2a2.63 2.63 0 0 0-2.63 2.63v10.5a2.63 2.63 0 0 0 5.25 0V4.63A2.63 2.63 0 0 0 14.67 2Z" />
        </symbol>
        <symbol id="plyr-pip" viewBox="0 0 24 24">
            <path d="M3.75 8c0-2.35 1.9-4.25 4.25-4.25h4a.75.75 0 0 0 0-1.5H8A5.75 5.75 0 0 0 2.25 8v8A5.75 5.75 0 0 0 8 21.75h8A5.75 5.75 0 0 0 21.75 16v-4a.75.75 0 0 0-1.5 0v4c0 2.35-1.9 4.25-4.25 4.25H8A4.25 4.25 0 0 1 3.75 16V8Z" />
            <path d="M21.53 2.47a.75.75 0 0 1 .22.53v5a.75.75 0 0 1-1.5 0V4.81l-6.72 6.72a.75.75 0 0 1-1.06-1.06l6.72-6.72h-3.2a.75.75 0 0 1 0-1.5h5c.2 0 .4.07.54.22Z" />
        </symbol>
        <symbol id="plyr-play" viewBox="0 0 20 20">
            <path d="M16.6 8.61a1.6 1.6 0 0 1 0 2.78L6.4 17.27A1.6 1.6 0 0 1 4 15.9V4.1c0-1.23 1.33-2 2.4-1.38L16.6 8.6Z" />
        </symbol>
        <symbol id="plyr-restart" viewBox="0 0 18 18">
            <path d="M9.7 1.2l.7 6.4 2.1-2.1c1.9 1.9 1.9 5.1 0 7-.9 1-2.2 1.5-3.5 1.5-1.3 0-2.6-.5-3.5-1.5-1.9-1.9-1.9-5.1 0-7 .6-.6 1.4-1.1 2.3-1.3l-.6-1.9C6 2.6 4.9 3.2 4 4.1 1.3 6.8 1.3 11.2 4 14c1.3 1.3 3.1 2 4.9 2 1.9 0 3.6-.7 4.9-2 2.7-2.7 2.7-7.1 0-9.9L16 1.9l-6.3-.7z" />
        </symbol>
        <symbol id="plyr-rewind" viewBox="0 0 18 18">
            <path d="M10.125 1L0 9l10.125 8v-6.171L18 17V1l-7.875 6.171z" />
        </symbol>
        <symbol id="plyr-settings" viewBox="0 0 24 24">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.49 2.25c-1.13 0-2.04.91-2.04 2.04v.07c0 .2-.12.37-.3.44l-.03.01-.14.06a.57.57 0 0 1-.63-.12c-.8-.8-2.09-.8-2.88 0l-.73.72c-.8.8-.8 2.09 0 2.88.17.17.22.41.13.63l-.07.18a.47.47 0 0 1-.44.3h-.07c-1.13 0-2.04.9-2.04 2.03v1.02c0 1.13.91 2.04 2.04 2.04h.07c.2 0 .37.12.43.3l.07.16c.1.23.05.47-.12.64-.8.8-.8 2.09 0 2.88l.73.73c.8.8 2.08.8 2.88 0a.57.57 0 0 1 .63-.13l.18.07c.17.07.29.24.29.44v.07c0 1.1.88 2 1.98 2.04H12.57a2.04 2.04 0 0 0 1.98-2.04v-.07c0-.2.12-.37.3-.44l.03-.01.14-.06a.57.57 0 0 1 .63.12c.8.8 2.09.8 2.88 0l.73-.72c.8-.8.8-2.09 0-2.88a.57.57 0 0 1-.13-.63l.07-.18a.47.47 0 0 1 .44-.3h.07c1.13 0 2.04-.9 2.04-2.03v-1.02c0-1.13-.91-2.04-2.04-2.04h-.07a.47.47 0 0 1-.43-.3 7.99 7.99 0 0 0-.07-.16.57.57 0 0 1 .12-.64c.8-.8.8-2.09 0-2.88l-.73-.73c-.8-.8-2.08-.8-2.88 0a.57.57 0 0 1-.63.13 8.12 8.12 0 0 0-.18-.07.47.47 0 0 1-.29-.44v-.07c0-1.13-.91-2.04-2.04-2.04l-.68.01h-.34Zm.05 18H12.51c.3 0 .54-.24.54-.54v-.07c0-.8.48-1.53 1.24-1.83l.04-.02.1-.04a2.07 2.07 0 0 1 2.28.44c.2.21.55.21.76 0l.72-.72a.54.54 0 0 0 0-.76c-.6-.6-.77-1.5-.44-2.28l.06-.14a1.97 1.97 0 0 1 1.83-1.24h.07c.3 0 .54-.24.54-.54v-1.02c0-.3-.24-.54-.54-.54h-.07c-.8 0-1.53-.48-1.83-1.24l-.06-.15a2.07 2.07 0 0 1 .45-2.27.54.54 0 0 0 0-.76l-.73-.72a.54.54 0 0 0-.76 0 2.07 2.07 0 0 1-2.41.38 1.97 1.97 0 0 1-1.25-1.83v-.07c0-.3-.24-.54-.54-.54a37.04 37.04 0 0 1-1.02 0c-.3 0-.54.24-.54.54v.07c0 .8-.48 1.53-1.24 1.83l-.04.02-.1.04c-.78.33-1.68.15-2.28-.44a.54.54 0 0 0-.76 0l-.72.72a.54.54 0 0 0 0 .76c.6.6.77 1.5.44 2.28a1.97 1.97 0 0 1-1.9 1.38H4.3c-.3 0-.54.24-.54.54v1.02c0 .3.24.54.54.54h.07c.8 0 1.53.48 1.83 1.24l.06.15c.33.78.15 1.67-.45 2.27a.54.54 0 0 0 0 .76l.73.72c.2.21.55.21.76 0a2.07 2.07 0 0 1 2.41-.38c.77.3 1.25 1.03 1.25 1.83v.07c0 .3.24.54.54.54h.05Zm-1.13-9.84a2.25 2.25 0 1 1 3.18 3.18 2.25 2.25 0 0 1-3.18-3.18ZM9.35 9.35a3.75 3.75 0 1 1 5.3 5.3 3.75 3.75 0 0 1-5.3-5.3Z" />
        </symbol>
        <symbol id="plyr-volume" viewBox="0 0 24 24">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.37 4.68a1.75 1.75 0 0 1 2.88 1.34v11.96c0 1.5-1.74 2.3-2.88 1.34l-3.77-3.2H3.5c-.97 0-1.75-.78-1.75-1.75V9.63c0-.96.78-1.75 1.75-1.75h2.1l3.77-3.19Zm1.38 1.34a.25.25 0 0 0-.41-.2L6.36 9.2a.75.75 0 0 1-.49.17H3.5a.25.25 0 0 0-.25.25v4.76c0 .13.11.24.25.24h2.38c.17 0 .34.07.48.18l3.98 3.37c.16.14.41.03.41-.19V6.02ZM14.9 7.62a.75.75 0 0 1 1.04-.23A5.41 5.41 0 0 1 18.25 12c0 1.96-.9 3.72-2.32 4.6a.75.75 0 1 1-.79-1.27c.9-.56 1.61-1.8 1.61-3.33 0-1.54-.7-2.78-1.61-3.35a.75.75 0 0 1-.24-1.04Z" />
        </symbol>
    </svg>
</template>

<script>
export default {
    name: 'PlyrIcons'
};
</script>
