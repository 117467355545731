import { defineNuxtPlugin } from 'nuxt/app';

const tr = {
    keys: {},
    add: (key, value) => tr.keys[key] = value,
    trans: (key, params = {}) => Object.keys(params).reduce((t, p) => t.replace(`%${p}%`, params[p]), tr.keys[key] || key)
};
window.Translator = tr;

const script = document.createElement('script');
script.src = 'https://frontend.coassemble.com/translations/messages/en.js';
document.head.appendChild(script);

export const $trans = tr.trans;
export default defineNuxtPlugin(function Translation(app) {
    app.vueApp.config.globalProperties.$trans = tr.trans;
    app.vueApp.config.globalProperties.$transChoice = tr.transChoice;
});
