
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93wZUYp0BfGW0JPSnX1dZ5Heur7Xh3VHA9H8vzHO96pdAMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/[...slug].vue?macro=true";
import { default as addonsgt9c3gWMFNekn9GhElZngksdauN3coSimwZghGdOgv4Meta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/admin/addons.vue?macro=true";
import { default as migrationq82_i4NKLrnVlRHBVY_bUPjiF4UPXakyFZwVS1soJu4Meta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/admin/migration.vue?macro=true";
import { default as userseX3OcA5X5NUOKUmVEleHNOZ_bZygZizTjSG97cclAtcMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/admin/users.vue?macro=true";
import { default as workspacesoMhiXFFqCAnxLclRMZo6hhj_45c5dsqGJEo9GjFuh8hqgMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/admin/workspaces.vue?macro=true";
import { default as adminLEHOmdHWtqy_45p4Z_mXeip7ReQUdoAcXhYsHNoGd6CQEMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/admin.vue?macro=true";
import { default as ai_45course_45generatorvmwIEiloBtihPnaCZIc6rEYGjlDzhQYRFQoFkcqV7tsMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/ai-course-generator.vue?macro=true";
import { default as builddeudTHZKsHhGBNgnbUSK3GZkYX2j365qASqNVI4gLzUMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/build.vue?macro=true";
import { default as _91key_938y5zEzSEp6TUnfAKXpTHuCnRafVgNLgeeBUdgS2awF8Meta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/c/[collectionKey]/course/[key].vue?macro=true";
import { default as index7RnggxFjXyBZXBsOnXiYXWLwGzPlHph2gTNp1TqutHgMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/c/[collectionKey]/index.vue?macro=true";
import { default as _91id_93ouexPLtPH_454S82a4T64PJmvfjRCWMKkbs6Cf4nBLm5AMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/collection/[id].vue?macro=true";
import { default as course_45templatesF4JHyXLm_uoqGb_xePfi6F_zzzqFXXzGtn7mm5h7i_EMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/course-templates.vue?macro=true";
import { default as _91id_93tG6sTZ_zWYrfxAd7ZKRmqxmM3C3OYab0Rlh07d1JyT8Meta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/course/[id].vue?macro=true";
import { default as collectionsw_45RcqraAxpzQ1dn_bSaE9054dOIO7opPcZtbQIvwqj4Meta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/dashboard/collections.vue?macro=true";
import { default as coursesUWEutdYZOrDBCrm31UL3x4NyunXWEz0OW05W49NksG0Meta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/dashboard/courses.vue?macro=true";
import { default as _91id_933TPgDu_NYF9xl9sRblykYol2gPq8JxU6S_45dtT1c8rfUMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/dashboard/folder/[id].vue?macro=true";
import { default as dashboardjgoCRlkC__45UjGt_45NTxL7j_45KjhksnqqElaGY0S_45KdXzcMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/dashboard.vue?macro=true";
import { default as _91token_938xTjo5YTtXUV1lp7gRsi780FAUi_0H4QzaDz7NF1nkgMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/embed/[token].vue?macro=true";
import { default as _91token_93Kkj1e1gF_45ASdKe1fooXi7Vjv38dmeVFEHywVLiNo6pgMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/embed/collection/[token].vue?macro=true";
import { default as headlessn39hjGsQu9Yy292O5GrgOsaVC5k0ixJ4THuSSKuFDDMMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/headless.vue?macro=true";
import { default as indexWUQUf814_9Zbau7jPOhbiCKpHXkWalT5fSrvGP_45EJ1sMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/index.vue?macro=true";
import { default as setupXmU_ad6RuNEz1eKddpU2ct4evPteu7Xhm_jEUJ3nq3gMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/intercom/setup.vue?macro=true";
import { default as successiI5GM0koJ_bKM_45VdIzIZuqmHRUonID6SCFf9Hj4yU1UMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/intercom/success.vue?macro=true";
import { default as _91key_93SQpQYS4C9DQhJ2vhub5v8FM2TNmIIe3_yxqtdzS_gFYMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/invitation/[key].vue?macro=true";
import { default as _91key_93EDc_EJU9dizwk7Xu2tiq1dEzmcp_AWDXkXOGj_qEcksMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/learn/[key].vue?macro=true";
import { default as pricingh4VcjKQMky3ePW4CsDWcr4S66_455GycVS4sonrAaDAWEMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/pricing.vue?macro=true";
import { default as _91code_93UYqmpbdIelQGfiLbkvpuvqzZWG501iQ1U7i2MVT70p8Meta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/redeem/[code].vue?macro=true";
import { default as redirectZqwc5RX_6YiJYnni9VGPUq0vALOD61401Vg_GNxLGjIMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/redirect.vue?macro=true";
import { default as _91_46_46_46slug_93LSz3TZaPkg6Pq2TDg3NEcsb35QotWzPHT5z95k_ef9MMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/resource/[...slug].vue?macro=true";
import { default as resourcesMsfXlbp8HBtvIGGMoL6kmSKIRZpgaj1az7bB4hAWIh0Meta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/resources.vue?macro=true";
import { default as forgot_45passwordszTVCukuUajHKQRi6ez1aW7dn940Z8lGuWYiMXQYCw0Meta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/signin/forgot-password.vue?macro=true";
import { default as indexGOQg2xbcp5DAe5cbQ1Dt8ne403x_b93AMqCtYruNyvoMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/signin/index.vue?macro=true";
import { default as readytechIwBEEqaOzlN2zpMiealolvC_NkcGWSfD6r_0YAC1jH8Meta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/signin/readytech.vue?macro=true";
import { default as reset_45passwordnhjF_45UvJkjvewPuoVxz6LdcXkx06NZib9rAsvnJDYzYMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/signin/reset-password.vue?macro=true";
import { default as _91_46_46_46slug_93ioiTQoczIc2QJNlinLV74hHMhB_s10E_45O5wwMYKX_45vYMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/templates/[...slug].vue?macro=true";
import { default as _91id_930I1cQSz3YyLpEFElCtAxAlBPaEzQdj22YrLsiZBFhdkMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/thumbnail/[id].vue?macro=true";
import { default as transformkJSKTYngD_45_45yPrZ7_45V2Yp_OINjODT6709hlDZwxUy9gMeta } from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/transform.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93wZUYp0BfGW0JPSnX1dZ5Heur7Xh3VHA9H8vzHO96pdAMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/[...slug].vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: adminLEHOmdHWtqy_45p4Z_mXeip7ReQUdoAcXhYsHNoGd6CQEMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/admin.vue"),
    children: [
  {
    name: "Addons",
    path: "addons",
    meta: addonsgt9c3gWMFNekn9GhElZngksdauN3coSimwZghGdOgv4Meta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/admin/addons.vue")
  },
  {
    name: "Migration",
    path: "migration",
    meta: migrationq82_i4NKLrnVlRHBVY_bUPjiF4UPXakyFZwVS1soJu4Meta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/admin/migration.vue")
  },
  {
    name: "Users",
    path: "users",
    meta: userseX3OcA5X5NUOKUmVEleHNOZ_bZygZizTjSG97cclAtcMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/admin/users.vue")
  },
  {
    name: "Workspaces",
    path: "workspaces",
    meta: workspacesoMhiXFFqCAnxLclRMZo6hhj_45c5dsqGJEo9GjFuh8hqgMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/admin/workspaces.vue")
  }
]
  },
  {
    name: "ai-course-generator",
    path: "/ai-course-generator",
    meta: ai_45course_45generatorvmwIEiloBtihPnaCZIc6rEYGjlDzhQYRFQoFkcqV7tsMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/ai-course-generator.vue")
  },
  {
    name: "build",
    path: "/build",
    meta: builddeudTHZKsHhGBNgnbUSK3GZkYX2j365qASqNVI4gLzUMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/build.vue")
  },
  {
    name: "c-collectionKey-course-key",
    path: "/c/:collectionKey()/course/:key()",
    meta: _91key_938y5zEzSEp6TUnfAKXpTHuCnRafVgNLgeeBUdgS2awF8Meta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/c/[collectionKey]/course/[key].vue")
  },
  {
    name: "c-collectionKey",
    path: "/c/:collectionKey()",
    meta: index7RnggxFjXyBZXBsOnXiYXWLwGzPlHph2gTNp1TqutHgMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/c/[collectionKey]/index.vue")
  },
  {
    name: "collection-id",
    path: "/collection/:id()",
    meta: _91id_93ouexPLtPH_454S82a4T64PJmvfjRCWMKkbs6Cf4nBLm5AMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/collection/[id].vue")
  },
  {
    name: "course-templates",
    path: "/course-templates",
    meta: course_45templatesF4JHyXLm_uoqGb_xePfi6F_zzzqFXXzGtn7mm5h7i_EMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/course-templates.vue")
  },
  {
    name: "course-id",
    path: "/course/:id()",
    meta: _91id_93tG6sTZ_zWYrfxAd7ZKRmqxmM3C3OYab0Rlh07d1JyT8Meta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/course/[id].vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardjgoCRlkC__45UjGt_45NTxL7j_45KjhksnqqElaGY0S_45KdXzcMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/dashboard.vue"),
    children: [
  {
    name: "dashboard-collections",
    path: "collections",
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/dashboard/collections.vue")
  },
  {
    name: "dashboard-courses",
    path: "courses",
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/dashboard/courses.vue")
  },
  {
    name: "dashboard-folder-id",
    path: "folder/:id()",
    meta: _91id_933TPgDu_NYF9xl9sRblykYol2gPq8JxU6S_45dtT1c8rfUMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/dashboard/folder/[id].vue")
  }
]
  },
  {
    name: "embed-token",
    path: "/embed/:token()",
    meta: _91token_938xTjo5YTtXUV1lp7gRsi780FAUi_0H4QzaDz7NF1nkgMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/embed/[token].vue")
  },
  {
    name: "embed-collection-token",
    path: "/embed/collection/:token()",
    meta: _91token_93Kkj1e1gF_45ASdKe1fooXi7Vjv38dmeVFEHywVLiNo6pgMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/embed/collection/[token].vue")
  },
  {
    name: "headless",
    path: "/headless",
    meta: headlessn39hjGsQu9Yy292O5GrgOsaVC5k0ixJ4THuSSKuFDDMMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/headless.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexWUQUf814_9Zbau7jPOhbiCKpHXkWalT5fSrvGP_45EJ1sMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/index.vue")
  },
  {
    name: "intercom-setup",
    path: "/intercom/setup",
    meta: setupXmU_ad6RuNEz1eKddpU2ct4evPteu7Xhm_jEUJ3nq3gMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/intercom/setup.vue")
  },
  {
    name: "intercom-success",
    path: "/intercom/success",
    meta: successiI5GM0koJ_bKM_45VdIzIZuqmHRUonID6SCFf9Hj4yU1UMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/intercom/success.vue")
  },
  {
    name: "invitation-key",
    path: "/invitation/:key()",
    meta: _91key_93SQpQYS4C9DQhJ2vhub5v8FM2TNmIIe3_yxqtdzS_gFYMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/invitation/[key].vue")
  },
  {
    name: "learn-key",
    path: "/learn/:key()",
    meta: _91key_93EDc_EJU9dizwk7Xu2tiq1dEzmcp_AWDXkXOGj_qEcksMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/learn/[key].vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingh4VcjKQMky3ePW4CsDWcr4S66_455GycVS4sonrAaDAWEMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/pricing.vue")
  },
  {
    name: "redeem-code",
    path: "/redeem/:code()",
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/redeem/[code].vue")
  },
  {
    name: "redirect",
    path: "/redirect",
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/redirect.vue")
  },
  {
    name: "resource-slug",
    path: "/resource/:slug(.*)*",
    meta: _91_46_46_46slug_93LSz3TZaPkg6Pq2TDg3NEcsb35QotWzPHT5z95k_ef9MMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/resource/[...slug].vue")
  },
  {
    name: "resources",
    path: "/resources",
    meta: resourcesMsfXlbp8HBtvIGGMoL6kmSKIRZpgaj1az7bB4hAWIh0Meta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/resources.vue")
  },
  {
    name: "signin-forgot-password",
    path: "/signin/forgot-password",
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/signin/forgot-password.vue")
  },
  {
    name: "signin",
    path: "/signin",
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/signin/index.vue")
  },
  {
    name: "signin-readytech",
    path: "/signin/readytech",
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/signin/readytech.vue")
  },
  {
    name: "signin-reset-password",
    path: "/signin/reset-password",
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/signin/reset-password.vue")
  },
  {
    name: "templates-slug",
    path: "/templates/:slug(.*)*",
    meta: _91_46_46_46slug_93ioiTQoczIc2QJNlinLV74hHMhB_s10E_45O5wwMYKX_45vYMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/templates/[...slug].vue")
  },
  {
    name: "thumbnail-id",
    path: "/thumbnail/:id()",
    meta: _91id_930I1cQSz3YyLpEFElCtAxAlBPaEzQdj22YrLsiZBFhdkMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/thumbnail/[id].vue")
  },
  {
    name: "transform",
    path: "/transform",
    meta: transformkJSKTYngD_45_45yPrZ7_45V2Yp_OINjODT6709hlDZwxUy9gMeta || {},
    component: () => import("/home/runner/work/coassemble-author/coassemble-author/frontend/src/views/transform.vue")
  }
]