import axios from 'axios';
import { defineNuxtPlugin } from 'nuxt/app';

export default defineNuxtPlugin(function Http(app) {
    const baseURL = app.$config.public.baseURL;
    const http = axios.create({ 
        baseURL, 
        withCredentials: true
    });
    
    http.interceptors.response.use(response => {
        const newToken = response.headers['x-client-id-refresh'];
        if (newToken) http.defaults.headers.common['X-Client-Id'] = newToken;
        return response;
    }, error => {
        return Promise.reject(error);
    });
    
    app.vueApp.config.globalProperties.$http = http;
    app.vueApp.config.globalProperties.$http.baseUrl = baseURL;
});
